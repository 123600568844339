
import React, { useState } from "react";
// reactstrap components
import {

  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Table,
  Input,
  Form
} from "reactstrap";

// core components
import {ApiTransactions} from "../../services" ;
import { store } from 'react-notifications-component';



const EditStatusModal = (props) => {


 
    const [inputs , setInputs] = useState ({
      statusToken : props.body.statusToken , 

       
       },[])
         function handleChange(e) {

           const { name, value } = e.target;
           setInputs(inputs => ({ ...inputs, [name]: value }));
           }
           
           function HandleClick (e)  {
            const { name, value } = e.target;
            setInputs(inputs => ({ ...inputs, [name]: value }));
          }

        
           async function handleSubmit() {
            props.toggle();
            try {
           await ApiTransactions .patch('/'+props.body._id,inputs);
           store.addNotification({
            title: "Success",
            message : "Account credit has ben updated!",
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            }
          });
           props.Transactions();  
        }catch(e){
          store.addNotification({
            title: "Error",
            message : "Validation error!",
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            }
          });        
        }
          }

    return (
      <>
    <Modal isOpen= {props.isOpen} toggle={props.toggle} modalTransition= {{timeout: 100}}>
                      <Form role="form"  >

    <ModalHeader toggle={props.toggle}>{props.title}</ModalHeader>
    <ModalBody>
        <Table className="align-items-center table-flush" responsive>
         
            <tbody>
            <tr>
                <td>Status:</td>
                <td><Input  type='select' name='statusToken' defaultValue={inputs.statusToken}
                                              onChange= {HandleClick}

                >
                  <option value='paid'>paid</option>
                  <option value= 'unpaid'>unpaid</option>
                  </Input></td>
            </tr>
     

         
            </tbody>
        </Table>
    
    </ModalBody>
    <ModalFooter>
      <Button color="primary" onClick={handleSubmit}>Update</Button>{' '}
      <Button color="secondary" onClick={props.toggle}>Cancel</Button>
    </ModalFooter>
    </Form>

  </Modal>

  </>
    )
 
  }


  export default EditStatusModal ;
