
import React, { useState } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col
} from "reactstrap";
// core components
import Header from "components/Headers/Header.jsx";
import { connect } from "react-redux";
import  { updateProfile } from "../../actions/user";
import { withRouter } from "react-router-dom";
import UploadFile from "components/UploadFile/UploadFile.jsx";
import ChangePasswordModal from 'components/ModalUsers/ChangePassword' ;
import { store } from 'react-notifications-component';


const Profile = (props) => {

const [inputs , setInputs] = useState ({
 name : props.userInfo.name,
 email : props.email ,
 phone : props.userInfo.phone,
 adress : props.userInfo.adress,
},[]) ;

// const [file , setFile] = useState (0);
const [changePasswordModal, setChangePasswordModal] = useState(false);
    const toggleChangePassword = () => setChangePasswordModal(!changePasswordModal);

    
  function handleChange(e) {
    setInputs({
      name : props.userInfo.name,
      email : props.email ,
      phone : props.userInfo.phone,
      adress : props.userInfo.adress,
    })
    const { name, value } = e.target;
    setInputs(inputs => ({ ...inputs, [name]: value }));
    }


    async function handleSubmit(e) {

      e.preventDefault();
      
    try {
 
      await props.updateProfile(inputs); 
      store.addNotification({
        title: "Success",
        message : "Profile updated!",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 2000,
        }
      });
        }catch(e){
          store.addNotification({
            title: "Error !",
            message : "Validation error",
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: true
            }
          });
      }
   }

  return (
      <>
            <Header/>
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
          <UploadFile />  

          <div className="col">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col >
                      <h3 className="mb-0">My account</h3>
                    </Col>
                    </Row>
                    <Row>
                    <Col className="text-right" >
                      <Button
                        color="primary"
                        onClick={toggleChangePassword}
                        size="sm"
                      >
                        Change my password
                      </Button>
                    </Col>
                    </Row>
              
                </CardHeader>
                <CardBody>
                  <Form role="form"  onSubmit={handleSubmit}>
                    <h6 className="heading-small text-muted mb-4">
                      User information
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Email address
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-email"
                              defaultValue={props.userInfo.email}
                              type="email"
                              name="email"
                              onChange= {handleChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              name
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue={props.userInfo.name}
                              id="input-first-name"
                              placeholder="First name"
                              type="text"
                              name="name"
                              onChange= {handleChange}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                       
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-phone"
                            >
                              phone
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue={props.userInfo.phone}
                              id="input-first-name"
                              placeholder="phone"
                              type="phone"
                              name="phone"
                              onChange= {handleChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-adress"
                            >
                              adress
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue= {props.userInfo.adress}
                              id="input-adress"
                              name="adress"
                              placeholder="adress"
                              type="text"
                              onChange= {handleChange}
                            />
                          </FormGroup>
                        </Col>
             
                      </Row>
                      <Row>
                        <Col className="text-right" >
                          <Button color="primary" size="xs" type="submit">Update             </Button>
                        </Col>
                      </Row>
                    </div>
                  
            
                   
                  </Form>
                </CardBody>
              </Card>
</div>
          </Row>
        </Container>   
        <ChangePasswordModal title = 'Change my password' isOpen= {changePasswordModal} toggle={toggleChangePassword} >
        </ChangePasswordModal>
      </>
    );
  }

const mapStateToProps = (state) => {
  return {
    isLogged : state.auth.isLogged, 
    role : state.auth.role , 
    email : state.auth.email,
    userInfo : state.auth.userInfo ,
    error : state.error , 
  }
}
export default withRouter (connect( mapStateToProps,{updateProfile})(Profile) ) ; 