
import React, { useState } from "react";
import {

    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,

  } from "reactstrap";
  import ShowModal from './../../components/ModalAccountsAdmin/Show.jsx';
  import EditModal from './../../components/ModalAccountsAdmin/Edit.jsx'
  import DeleteModal from './../../components/ModalAccountsAdmin/Delete.jsx'
  import UploadModal from "components/ModalAccountsAdmin/Upload.jsx"
  import {ApiPriceList} from "../../services" ;
  import ReneweModal from './../../components/ModalAccounts/Renew.jsx' ; 

const Actions =(props) => {
  

  const [state , setState] = useState ({
    priceList :[],
    periods : []
  });


  function setPeriods (periods){
      setState({...state,periods});

  }
  async function fetchAbonnementType () {
    const  res =  await ApiPriceList.get() ;
        const priceList = res.data.data.data ;
       const periods =  await fetchPeriods();
       setState({periods,priceList});
     }
     
   async function fetchPeriods ()
    {
      const res = await ApiPriceList.get(props.id.period.type._id)
        const periods = res.data.data.data.periods ; 
        return periods;
    } 


    const [showModal, setShowModal] = useState(false);
    const toggleShow = () => setShowModal(!showModal);

    const [editModal, setEditModal] = useState(false);
    const toggleEdit = async () => {
      if (!editModal)
     await fetchAbonnementType();  
      setEditModal(!editModal);

    
    };
    const [deleteModal, setDeleteModal] = useState(false);
    const toggleDelete = () => setDeleteModal(!deleteModal);
    const [uploadModal, setUploadModal] = useState(false);
    const toggleUpload = () => setUploadModal(!uploadModal);
    const [renewModal, setRenewModal] = useState(false);
    const toggleRenew = () => setRenewModal(!renewModal);
    return(<>
 <UncontrolledDropdown>
   <DropdownToggle
     className="btn-icon-only text-light"
     data-boundary="viewport"
     href={e => e.preventDefault()}
     role="button"
     size="sm"
     color=""
     onClick={e => e.preventDefault()}
   >
     <i className="fas fa-ellipsis-v" />
   </DropdownToggle>
   <DropdownMenu className="dropdown-menu-arrow" right>
     <DropdownItem
     href={toggleShow}
       onClick={toggleShow}
       className="btn"
     >
       Show
     </DropdownItem>
     <DropdownItem
       href={toggleEdit}
       onClick={toggleEdit}
       className="btn"
     >
       Edit
     </DropdownItem>
     <DropdownItem
       href={toggleRenew}
       onClick={toggleRenew}
       className="btn"

     >
       Renew
     </DropdownItem>

     {/* <DropdownItem        className="btn"
>
    <a href = {"files/"+props.id.file} download>Donwload File</a>
    </DropdownItem>
    <DropdownItem         className="btn"
 href={toggleUpload}
       onClick={toggleUpload}>
            Upload file 
    </DropdownItem> */}
   </DropdownMenu>
 </UncontrolledDropdown>


 
        <ShowModal title = 'Account info' body = {props.id} isOpen= {showModal} toggle={toggleShow} >
        </ShowModal>
        <EditModal setPeriods = {setPeriods} priceLists = {state.priceList} periods = {state.periods} title = 'Edit account' body = {props.id} isOpen= {editModal} toggle={toggleEdit} >
        </EditModal>
        <DeleteModal title = 'Delete account' body = {props.id} isOpen= {deleteModal} toggle={toggleDelete} >
        </DeleteModal>
        <UploadModal  title = 'Upload file' body = {props.id} isOpen= {uploadModal} toggle={toggleUpload}></UploadModal>
        <ReneweModal title = 'Renew account' body = {props.id} isOpen= {renewModal} toggle={toggleRenew} />


</>


    )
}


export default Actions  ; 


