
import React, { useState, useEffect } from "react";
// reactstrap components
import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  Button,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.jsx";
import { ApiPriceList , ApiClients} from "../../services" ;
import moment from 'moment';
import Actions from "../../components/tables/ActionsAdmin";
import CreateModal from './../../components/ModalAccountsAdmin/Create.jsx'
import Tables from "views/pages/TableDark.jsx";
import useSwr from "swr";
import { CSVLink } from "react-csv";




const AccountsAdmin = () => {

  const url =  'http://itautronic.com/api/v1/clients/' ; 
  const fetcher = (...args) => ApiClients.get('/inProgress').then((res) => res.data) ; 
  const {data} = useSwr(url,fetcher) ;   
 
  const headers = [
    { label: "type", key: "type" },
    { label: "period", key: "period" },
    { label: "client", key: "client" },
    { label: "email", key: "email" },
    { label: "phone", key: "phone" },
    { label: "note", key: "note" },
    { label: "status", key: "status" },
    { label: "creation_date", key: "creation_date" },
    { label: "end_date", key: "end_date" },
    { label: "username", key: "username" },
    { label: "password", key: "password" }

  ];

  let csv = []; 
  const [csvData,setCsvData] = useState ([]) ; 

  useEffect(()=>{
    if (data)
    {
      data.data.map(async (d) => {
        csv.push({
          'type' : d.period.type.type , 
          'period' : d.period.period ,
           'client' : d.clientName , 
            'email' : d.email , 
            'phone' : d.phone , 
            'note' : d.note ,
            'status' : d.status , 
            'creation_date' : convertDate(d.starter_date) , 
            'end_date' : convertDate(d.end_date) , 
          'username' : d.username , 
          'password' : d.password
        });  
      });
      setCsvData(csv);
    }
  },[data]) ; 

     async function fetchAbonnementType () {
      const  res =  await ApiPriceList.get() ;
          const priceList = res.data.data.data ;
         const periods =  await fetchPeriods(priceList[0]);
         setState2({periods,priceList});
       }
     async function fetchPeriods (priceList)
      {
        const res = await ApiPriceList.get(priceList.id)
          const periods = res.data.data.data.periods ; 
          // const period = periods[0];
          // setInputs({...inputs,period})
          return periods;
      } 
      
      const [state2 , setState2] = useState ({
        priceList :[],
        periods : []
      });
      

  const [createModal, setCreateModal] = useState(false);
    const toggleCreate = async () => 
    {
        if (!createModal)    await fetchAbonnementType();  
      setCreateModal(!createModal);
    
    }

    function setPeriods (periods){
      setState2({...state2,periods});

  }

const columns = React.useMemo (
  () => [
    {
      Header: 'Subscription type' , 
      accessor : properties => properties.period.type.type,
    },
    {
      Header: 'Period' , 
      accessor : 'period.period',
    },

    {
      Header: 'Username' , 
      accessor : 'username',
    },
  
    {
      Header: 'Client' , 
      accessor : 'clientName',
    },
    
    {
      Header: 'Creation date' , 
      accessor : properties => convertDate(properties.starter_date),
    },
    {
      Header: 'Expiration date' , 
      accessor : properties => convertDate(properties.end_date),
    },
    {
      Header: 'Created by' , 
      accessor : properties => properties.user.name,
    },
    {
      Header: 'Status' , 
      accessor : 'status',
    },
    {
      id: 'selection',
      Header: () => (
        <div>
          Actions
        </div>
      ), 
      Cell: props => (
        <div>
          <Actions id = {props.row} />
        </div>
      ),
        },
  ],[]
)
  

  function convertDate ( date )
  {
    return moment(date).format("DD/MM/YYYY");
  }



    return (
      <>
        <Header />
        {/* Page content */}

        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
            <Card className="bg-default shadow">
            <CardHeader className="bg-transparent border-0">
                  <Row className="align-items-center">
                    <Col xs="4">
                      <h3  className="text-white mb-0">All accounts</h3>
                    </Col>
                    <Col className="text-right" xs="8">
                      <Button
                        color="primary"
                        onClick={toggleCreate}
                        size="sm"
                      >
                        Create new account
                      </Button>
                    </Col>
                  </Row>
                  <br></br>
                  <Row>
                  <Col className="text-right" xs="12">
                 
                      <CSVLink data={csvData} headers= {headers}>
                      <Button
                        color="primary"
                        size="sm"
                      >
                    Download csv
                      </Button>
                        </CSVLink>

                    </Col>
                  </Row>
                 
                </CardHeader>
        <Tables columns = {columns} tbody = {data? data.data : []} />
        
              </Card>
            </div>
          </Row>
        </Container>



        <CreateModal setPeriods={setPeriods} periods= {state2.periods} priceLists = {state2.priceList} title = 'Create new account' isOpen= {createModal} toggle={toggleCreate} >
        </CreateModal>

      </>
    );
  }

  
  export default AccountsAdmin ; 
  

