
import React, { useState } from "react";
// reactstrap components
import {
  CardHeader,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Input,
  Row,
  Col,
  FormGroup
} from "reactstrap";
// core components
import { useTable,usePagination,useSortBy,useFilters,useGlobalFilter,useAsyncDebounce } from 'react-table'
import BadgeStatus from "../../components/BadgeStatus/BadgeStatus.jsx";



const inputStyle = {
  backgroundColor : '',
  color : ''
};

const showStyle = {
  backgroundColor : '',
  color : '',
  width: '150px' , height: '40px'
};

const gotoPageStyle = {
  backgroundColor : '',
  color : '',
  width: '100px' , height: '40px'
};


function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <>
  <input
      style = {inputStyle}
      className="form-control form-control-sm"
      type = "search"
        defaultValue={value || ""}
        onChange={e => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder='Search'
      />
    </>
  )
}


const TableWhite = (props) => {

 


  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    []
  )

  const [state,setState] = useState ({
    tbody : [],
  })
  const data = React.useMemo(
    () => props.tbody
    
  )
  const columns = props.columns ;
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
      canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      preGlobalFilteredRows,
      setGlobalFilter,
      state: { pageIndex, pageSize  },
  } = useTable({ columns,
     data,
     filterTypes,
     initialState: { pageIndex: 0 },
     
     
    },useFilters,useGlobalFilter,useSortBy,usePagination  
  
    )
  
  
  
    function activePagination (p)  {
          if (p === pageIndex)
          {
            return ('active');
          }
    };
  
  
  

    return (
      <>
      <CardHeader className="bg-transparent border-0">
      <Row>
                  <Col xs="4">
                        
                  <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
              />
                  </Col>
                  <Col className="text-right" xs="4">
                
                    </Col>
                  </Row>
      </CardHeader>
      <div style={{
            minHeight: '200px',
            overflowY: 'auto'
          }}>
      <Table {...getTableProps()} className="table align-items-center table-flush table-responsive-xl"  >

                  <thead className="thead-light">
                    {headerGroups.map(headergroup => (
                      <tr {...headergroup.getFooterGroupProps()}>
                        {headergroup.headers.map(column => (
                          <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                            {column.render('Header')}
                            <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? <>   <i className="fas fa-angle-down" /></>
                        :  <>  <i className="fas fa-angle-up" /> </>
                      : ''}
                  </span>
                          </th>
                        ))}
                                              <th scope="col" />

                      </tr>
                    ))}          
                  </thead>
                  <tbody {...getTableBodyProps()}>
                  {page.map((row, i) => {
           prepareRow(row)
           return (
             <tr {...row.getRowProps()}>
               {row.cells.map(cell => {
                 return (
                   <td
                     {...cell.getCellProps()}
                    >
                      { cell.column.Header === 'Status' ?
                     <BadgeStatus value=  {cell.value}/>
                      :cell.render('Cell')}
                   </td>
                 )
               })}
            
             </tr>
           )
         })}
       </tbody>
                </Table>
</div>
              
                <CardFooter className="bg-transparent border-0">
     
      



                <nav aria-label="...">
<Pagination
      className="pagination justify-content-end mb-0"
      listClassName="justify-content-end mb-0"
    >
      <PaginationItem disabled={!canPreviousPage}>
            <PaginationLink onClick={() => gotoPage(0)} >
            <i className="fas fa-angle-double-left" />
                      <span className="sr-only">Previous</span>
            </PaginationLink>
      </PaginationItem>

      <PaginationItem disabled={!canPreviousPage}>
        <PaginationLink
          onClick={() => previousPage()}
          
        >
          <i className="fas fa-angle-left" />
          <span className="sr-only">Previous</span>
        </PaginationLink>
      </PaginationItem>

      {/* {pageOptions.map(page => {
        return ( <PaginationItem key= {page} className = {activePagination(page)}>
          <PaginationLink key= {page} onClick= {() => gotoPage(page)}>
          {page+1}
          </PaginationLink ></PaginationItem>)
      })} */}
        
        <PaginationItem key= {pageIndex} className = {activePagination(pageIndex)}>
          <PaginationLink key= {pageIndex} onClick= {() => gotoPage(pageIndex)}>
          {pageIndex+1}
          </PaginationLink >
          </PaginationItem>
          
          <PaginationItem >
          <PaginationLink disabled >
          of
          </PaginationLink >
          </PaginationItem>
      
          <PaginationItem key= {pageOptions.length} className = {activePagination(pageOptions.length -1)}>
          <PaginationLink key= {pageOptions.length} onClick= {() => gotoPage(pageOptions.length -1)}>
          {pageOptions.length}
          </PaginationLink >
          </PaginationItem>

      <PaginationItem disabled={!canNextPage}> 
        <PaginationLink
          onClick={() => nextPage()}
        >
          <i className="fas fa-angle-right" />
          <span className="sr-only">Next</span>
        </PaginationLink>
      </PaginationItem>

      <PaginationItem disabled={!canNextPage}>
        <PaginationLink onClick={() => gotoPage(pageCount - 1)} >
          <i className="fas fa-angle-double-right" />
          <span className="sr-only">Next</span>      
        </PaginationLink>
      </PaginationItem>   

    </Pagination>

</nav>

 
<Row>

<h4 className=" mb-0">
<FormGroup>
  | Go to page:{' '}
  <Input
    type="number"
    defaultValue={pageIndex + 1}
    onChange={e => {
      const page = e.target.value ? Number(e.target.value) - 1 : 0
      gotoPage(page)
    }}
    style={gotoPageStyle}
  />
</FormGroup>
</h4>{' '}
  <FormGroup>
  <Input
    style={showStyle}
    type= "select"
  value={pageSize}
  onChange={e => {
    setPageSize(Number(e.target.value))
  }}
>
  {[10, 20, 30, 40, 50].map(pageSize => (
    <option key={pageSize} value={pageSize}>
      Show {pageSize}
    </option>
  ))}
  
</Input>
</FormGroup>
</Row>


                </CardFooter>
  
  
      </>
    )





}








export default TableWhite ;
