import React from "react";
import {  Switch } from "react-router-dom";
import { connect } from 'react-redux';

import { Container } from "reactstrap";

import AdminNavbar from "components/Navbars/AdminNavbar.jsx";
import AdminFooter from "components/Footers/AdminFooter.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";

import PrivateRoute from "../utils/PrivateRoute";

import routes from "routes.js";
import  { checkLogged } from "../actions/auth";

import { withRouter } from "react-router-dom";


class Admin extends React.Component {
  componentDidMount(){
    this.props.checkLogged();
  
  }
  componentDidUpdate(e) {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.mainContent.scrollTop = 0;
  }

  getRoutes =  (routes) => {
    return routes.map( (prop, key) => {
      if (prop.layout === "/admin") {
        return (
          
          <PrivateRoute
            component={prop.component}
            path={prop.layout + prop.path}
            authority={prop.authority}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };
  render() {
    return (
      <>
        <Sidebar
          {...this.props}
          routes={routes}
          logo={{
            innerLink: this.props.role == 'admin' ? "/admin/index" : "/admin/dashboard-user",
            imgSrc: require("assets/img/brand/argon-react.png"),
            imgAlt: "...",
          }}
        />
        <div className="main-content" ref="mainContent">
          <AdminNavbar
            {...this.props}
            brandText={this.getBrandText(this.props.location.pathname)}
          />
          <Switch>{this.getRoutes(routes)}</Switch>
          <Container fluid>
            <AdminFooter />
          </Container>
        </div>
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    role : state.auth.role ,
    isAuthenticated: state.auth.token !== null
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    {checkLogged}
  )(Admin)
);
