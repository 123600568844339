//AUTH
export const LOGIN = "LOGIN";
export const SIGNUP = "SIGNUP";
export const LOGOUT = "LOGOUT";
export const CHECK_LOGGED = "CHECK_LOGGED";
export const LOGIN_SUCESS = "LOGIN_SUCESS";

//ERROR
export const SET_ERRORS = "SET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const RETURN_ERRORS = "RETURN_ERRORS";

//USERS or DATA
export const GET_ALL_USERS = "GET_ALL_USERS";
export const GET_PROFILE = "GET_PROFILE";
export const ADD_USER = "ADD_USER";
export const DEL_USER = "DEL_USER";
export const UPDATE_USER = "UPDATE_USER";

// ALERT 
export const SET_ALERT = "SET_ALERT";

//CLIENTS 
export const GET_CLIENTS = "GET_CLIENTS"