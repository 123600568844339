
import React, { useState,useEffect } from "react";
// reactstrap components
import {

  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Table,
  Input,
  Form
} from "reactstrap";

// core components
import {ApiClients,ApiPriceList,ApiUpload} from "services" ;
import { store } from 'react-notifications-component';
import  {mutate, trigger} from "swr";
const CreateModal = (props) => {
 
 
  const [file1, setFile1] = useState(''); // storing the uploaded file    // storing the recived file from backend
  
  useEffect(() => {

    setInputs ({
      clientName: '',
      email : '' ,
      phone : '',
      username : '' , 
      starter_date : Date.now() ,
      password : '',
      period : props.periods[0],
      note : '' ,   
      file : ''  
    });
    setFile1('');
 },[props]);


    const [inputs , setInputs] = useState ({
        clientName: '',
        email : '' ,
        phone : '',
        username : '' , 
        starter_date : Date.now() ,
        password : '',
        period : props.periods[0],
        note : '' ,   
        file : ''    
       },[])

         function handleChange(e) {
           const { name, value } = e.target;
           setInputs(inputs => ({ ...inputs, [name]: value }));
           }


         async  function handleFileChange(e) {
            const cancel = !e.target.files.length;
            const file1 = e.target.files[0]; // accesing file
            if (cancel) return;
            const size = file1.size ;
            const maxSize = 500000000;
            if (size < maxSize) {
              setFile1({file1});
            }     
          }

           function HandleClick (e)  {
            const { name, value } = e.target;
            setInputs(inputs => ({ ...inputs, [name]: value }));
          }

      
           async function handleSubmit(e) {
             e.preventDefault();
            if (!inputs.period)
            setInputs(inputs.period = props.periods[0]);
            let formData = new FormData();
            formData.append("file", file1.file1); 
      
            try {
              if (file1)
              {
           const res = await ApiUpload.post('',formData);
           const  file = await res.data.file ; 
           setInputs(inputs.file = file );
              }
              const url = 'http://itautronic.com/api/v1/clients'
            mutate(url) ;
           await ApiClients.post('',inputs);
           trigger(url);
          store.addNotification({
        title: "Success",
        message : "Account created!",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 2000,
        }
      });


  
         props.toggle();
        }catch(e){
          store.addNotification({
            title: "Error",
            message : "Validation Error !",
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            }
          });

        }
        
          }


          
        function onClickType (e)  {
          if (e.target.value !== 1)
          {
          ApiPriceList.get('/'+e.target.value).then(res => { 
          const periods = res.data.data.data.periods ;
          props.setPeriods(periods);
          const period = periods[0];
          setInputs({...inputs,period});
          })
        }

        }
    return (
    <Modal isOpen= {props.isOpen} toggle={props.toggle} modalTransition= {{timeout: 100}} className="modal-default">
                      <Form role="form" onSubmit= {handleSubmit}>

    <ModalHeader toggle={props.toggle}>{props.title}</ModalHeader>
    <ModalBody>
        <Table className="align-items-center table-flush table-dark text-light" responsive>
         
            <tbody>
            <tr>
                <td>Type :</td>
                <td>
                <Input className="bg-default"  type="select" name="select" id="exampleSelect"  onChange = {(e)=>onClickType(e)}>
              {props.priceLists.map(priceList => <option key={priceList.id} value= {priceList.id}>{priceList.type}</option>)}              
              </Input>
                </td>
            </tr>
            <tr>
                <td> Period :</td>
                <td>
                <Input className="bg-default"   type="select" name="period" id="exampleSelect"  onChange = {(e)=>HandleClick(e)}>
              {props.periods.map(period => <option key={period.id} value= {period._id}>{period.period}</option>)}              
              </Input>
                </td>
            </tr>
            <tr>
                <td>Client name :</td>
                <td><Input className="bg-default"  name='clientName' required='true'
                                              onChange= {handleChange}

                /></td>
            </tr>
            <tr>
                <td>Phone:</td>
                <td><Input className="bg-default"  name='phone' 
                                              onChange= {handleChange}

                /></td>
            </tr>

            <tr>
                <td>Email :</td>
                <td><Input className="bg-default"  name='email' 
                                              onChange= {handleChange}

                /></td>
            </tr>
            <tr>
                <td>Note:</td>
                <td><Input className="bg-default"  name='note' type='textarea' 
                                              onChange= {handleChange}

                /></td>
            </tr>
      
            <tr>
                <td>Username :</td>
                <td><Input className="bg-default"  name= 'username'     
                                     onChange= {handleChange}
/></td>
            </tr>
            <tr>
                <td>Password :</td>
                <td><Input className="bg-default"  name='password' 
                                              onChange= {handleChange}
                /></td>
            </tr>
            <tr>
                <td>File:</td>
                <td><Input className="bg-default"  name='file' type ='file' 
                                              onChange= {handleFileChange}

                /></td>
            </tr>
         
            </tbody>
        </Table>
    
    </ModalBody>
    <ModalFooter>
      <Button color="primary"  type="submit">Save</Button>{' '}
      <Button color="secondary" onClick={props.toggle}>Cancel</Button>
    </ModalFooter>
    </Form>
  </Modal>
    )
 
  }



  export default  CreateModal ; 
  

