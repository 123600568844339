

import * as TYPE from "./types";



export const setAlert = (obj) => (dispatch) => {
    return dispatch({
      type: TYPE.SET_ALERT,
      payload : {
        isOpenAlert : obj.isOpenAlert ,
        text : obj.text,
        color : obj.color 
      }
      
    })
  }