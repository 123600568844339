import * as TYPE from "../actions/types";

const initialState = {
  clients: [],
};

const clientReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPE.GET_CLIENTS:
          return{...state , ...action.payload}
    // case "FETCH_USER_DATA":
    //   return { ...state, ...action.payload };

    // case "LOGOUT":
    //   return { ...initialState };

    default:
      return state;
  }
};

export default clientReducer;
