
import React from "react";
// reactstrap components
import { Container} from "reactstrap";

class Header extends React.Component {
  render() {
    return (
      <>
        <div className="header bg-default pb-8 pt-5 pt-md-8">
  

        </div>
      </>
    );
  }
}


export default Header ;
