
import React, { useState } from "react";
// reactstrap components
import {

  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Table,
  Input,
  Form
} from "reactstrap";

// core components
import {ApiClients,ApiUpload} from "../../services" ;
import { store } from 'react-notifications-component';
import { connect } from "react-redux";
import  { getClients } from "../../actions/client";
import { withRouter } from "react-router-dom";

const UploadModal = (props) => {

  let formData = new FormData();


    const [inputs , setInputs] = useState ({
        file : props.body.file 
       },[]) ; 

       async  function handleFileChange(e) {
        const cancel = !e.target.files.length;
        const file1 = e.target.files[0]; // accesing file
        if (cancel) return;
        // const size =   file1.size ;
        // const  maxSize = 500000000;
        // if ( size < maxSize) {
            formData.append("file", file1); 
                    // }     
      }
          
        
           async function handleSubmit(e) {
             e.preventDefault();
            try {
                // if (formData.file)
              // {
           const res = await ApiUpload.post('',formData);
           const  file = await res.data.file ; 
           setInputs(inputs.file = file );
              // }

           await ApiClients.patch(props.body._id,inputs);
           store.addNotification({
            title: "Success",
            message : "File uploaded",
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            }
          });
          props.getClients();
          props.toggle();
        }catch(e){
            store.addNotification({
                title: "Error",
                message : "Upload error check filesize !!",
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                  duration: 2000,
                }
              });
        }
          }

    return (
      <>
    <Modal isOpen= {props.isOpen} toggle={props.toggle} modalTransition= {{timeout: 100}}>
                      <Form role="form"  >

    <ModalHeader toggle={props.toggle}>{props.title}</ModalHeader>
    <ModalBody>
        <Table className="align-items-center table-flush" responsive>
         
            <tbody>
           <tr>
           <td>File:</td>
                <td><Input name='file' type ='file' 
                                              onChange= {handleFileChange}

                /></td>
           </tr>
            </tbody>
        </Table>
    
    </ModalBody>
    <ModalFooter>
      <Button color="primary" onClick={handleSubmit}>Upload</Button>{' '}
      <Button color="secondary" onClick={props.toggle}>Cancel</Button>
    </ModalFooter>
    </Form>

  </Modal>

  </>
    )
 
  }

  const mapStateToProps = (state) => {
    return {
      clients : state.clients.clients, 
    }
  }
  
  export default withRouter (connect(mapStateToProps,{getClients})(UploadModal) ) ; 
  
