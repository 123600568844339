import React from 'react';
import {Badge} from 'reactstrap';

function BadgeStatus(props)
  { 
    if (props.value === 'enabled')
    
      return (  <Badge color="" className="badge-dot mr-4">
      <i className="bg-success" />
      Enabled
    </Badge>)
    
    else if (props.value === 'disabled')
    return (
      <Badge color="" className="badge-dot mr-4">
                          <i className="bg-danger" />
                          Disabled
                        </Badge>

    )
    else if (props.value === 'in progress')
     return (
      <Badge color="" className="badge-dot mr-4">
      <i className="bg-warning" />
      In Progress
    </Badge>  
     )
     else if (props.value === 'paid')
     return (
      <Badge color="" className="badge-dot mr-4">
      <i className="bg-success" />
      Paid
    </Badge>  
     )
     else if (props.value === 'unpaid')
     return (
      <Badge color="" className="badge-dot mr-4">
      <i className="bg-danger" />
      Unpaid
    </Badge>  
     )
    return null ;
  }

  export default BadgeStatus ; 