
import React, { useState, useEffect } from "react";
// reactstrap components
import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.jsx";
import moment from 'moment';
import { connect } from "react-redux";
import Tables from "views/pages/TableWhite.jsx";
import {format} from 'date-fns' ; 

import {ApiTransactions} from "services";

const styleAmount = ({
  color : 'red' ,
})

const TransactionsSaleAdmin = (props) => {


  useEffect(() => {
    ApiTransactions.get('/allTransactionsSale').then(res => {
      const tbody = res.data.data ;
      setState({tbody});

    });  },[]);

  const [state,setState] = useState ({
    tbody : [],
  })


const columns = React.useMemo (
  () => [
    {
      Header: 'Reference' , 
      accessor : 'ref',
    },
    {
      Header: 'User' , 
      accessor : properties => properties.user.name,
    },
    {
      Header: 'Account' , 
      accessor : 'client.username',
    },
    {
      Header: 'description' , 
      accessor : properties => properties.description,
    },
    {
      Header: 'Credit' , 
      accessor : properties =>  (<div style={styleAmount}>-{properties.price}</div>),
    },
    {
      Header: 'Remaining balance' , 
      accessor : 'remainingBalance',
    },

    {
      Header: 'Transaction Date' , 
      accessor : 'TransactionDate',
      Cell: ({ value }) => {return format(new Date(value) , 'dd/MM/yyyy')},
    },


    
  ],[]
)

  

  function convertDate ( date )
  {
    return moment(date).format("DD/MM/YYYY");
  }



    return (
      <>
        <Header />
        {/* Page content */}

        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
            <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3  className="mb-0">Transactions</h3>
                    </Col>
                    <Col className="text-right" xs="4">
    <h3  className="mb-0">Credit : {props.credit}</h3>

                    </Col>
                  </Row>
                </CardHeader>


                <Tables columns = {columns} tbody = {state.tbody} />

              </Card>
            </div>
          </Row>
        </Container>

      </>
    );
  }

  const mapStateToProps = (state) => {
    return {
      credit : state.auth.userInfo.credit  
    }
  }

export default (connect(mapStateToProps)(TransactionsSaleAdmin) ) ;















