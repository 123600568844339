
import React, { useEffect, useState } from "react";
// reactstrap components
import {

  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Table,
  Input,
  Form
} from "reactstrap";

// core components
import {ApiTransactions,ApiPriceList,ApiPeriod} from "services" ;
import { store } from 'react-notifications-component';
import  {mutate, trigger} from "swr";
import moment from 'moment';

const CreateModal = (props) => {

  const [periods , setPeriods]  = useState([]) ; 
  const [period , setPeriod]  = useState('') ; 
  const [periodFinal , setPeriodFinal]  = useState('') ; 

  const [price , setPrice] = useState(0);
  
  const [note,setNote] = useState(props.body.note);



  useEffect( () => {
 
    setNote(props.body.note);

   if (props.isOpen)
   {
     ApiPriceList.get(props.body.period.type._id).then( async (res)=> {
      setPeriods(res.data.data.data.periods.slice(1)) ; 
      if (props.body.period.period === "24 hours")
      {
       if (res.data.data.data.periods.slice(1).length != 0)
       {
       setPeriod(res.data.data.data.periods.slice(1)[0].period);
       setPrice(res.data.data.data.periods.slice(1)[0].price);
       setPeriodFinal(res.data.data.data.periods.slice(1)[0])
       }
      }
       else 
       {
         setPeriod(props.body.period);
         setPrice(props.body.period.price);
         setPeriodFinal(props.body.period)
       }
    });
   }
  
    
 },[props]);




           
          async function HandleClick (e)  {
            const { name, value } = e.target;
            setPeriod(inputs => ({ ...inputs, [name]: value }));
           const  res = await ApiPeriod.get('/'+e.target.value);
            setPrice(res.data.data.data.price);
            setPeriodFinal(res.data.data.data);
          }

          function handleChange(e) {
            const { name, value } = e.target;
            setNote(value);
            }
 

        


           async function handleSubmit(e) {
             props.toggle();
             e.preventDefault();
             if (!periodFinal)
             setPeriodFinal(periods[0]);
            try {
              const url =  'http://itautronic.com/api/v1/clients/myAccounts' ; 
              mutate(url) ;
              await ApiTransactions.post('/renewAccount',{id : props.body._id , note: note , period : periodFinal ,  operation : 'sale'  });
              trigger(url);

              store.addNotification({
                title: "Renewal request has been sent",
                message : "must wait 30 minute for activation  !",
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                  duration: 4000,
                }
              });

        }catch(e){
          store.addNotification({
            title: "Error",
            message : "Your balance is insufficient !",
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            }
          });

        }

  
        
          }

        const  setSelected = (id) => {
              if (period._id === id )
              return true ;
              else 
              return false
          }

    return (
    <Modal isOpen= {props.isOpen} toggle={props.toggle} modalTransition= {{timeout: 100}} className="modal-default">
                      <Form role="form"  onSubmit={handleSubmit}>

    <ModalHeader toggle={props.toggle}>{props.title}</ModalHeader>
    <ModalBody>
        <Table className="align-items-center table-flush table-dark text-light" responsive>
         
            <tbody>
            <tr>
                <td>Username :</td>
                <td>{props.body.username}</td>
            </tr>
            <tr>
                <td>Password :</td>
                <td>{props.body.password}</td>
            </tr>
            <tr>
                <td>Expiration Date :</td>
                <td>{moment(props.body.end_date).format("DD/MM/YYYY")}</td>
            </tr>
            <tr>
                <td>Type :</td>
                <td>{props.body.period.type.type}</td>
            </tr>
            <tr>
                <td> Note :</td>
                <td>
                <Input className="bg-default" type="textarea" name="note"  defaultValue={note}  onChange= {handleChange}/>         
                </td>
            </tr>
            <tr>
                <td> Period :</td>
                <td>
                <Input className="bg-default" type="select" name="period" id="exampleSelect"   onChange = {(e)=>HandleClick(e)}>
              {periods.map(periode => <option key={periode._id} value= {periode._id} selected= {setSelected(periode._id)}>{periode.period}</option>)}              
              </Input>
                </td>
            </tr>
            <tr>
            <td>
                <label>Price :</label>
              </td>
              <td >
                <h3 className='text-white bg-success form-control text-center'>{price} Credits</h3>
              </td>
            </tr>
         
             
            </tbody>
        </Table>
    
    </ModalBody>
    <ModalFooter>
      <Button color="primary" type = 'submit'>Save</Button>{' '}
      <Button color="secondary" onClick={props.toggle}>Cancel</Button>
    </ModalFooter>
    </Form>
  </Modal>
    )
 
  }

export default CreateModal ;
