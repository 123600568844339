import axios from "axios";

const ApiUsers = axios.create({
  baseURL: "/api/v1/users",
  headers: {
    "X-Requested-With": "XMLHttpRequest",
  },
});

const ApiClients = axios.create({
  baseURL: "/api/v1/clients",
  headers: {
    "X-Requested-With": "XMLHttpRequest",
  },
});

const ApiTransactions= axios.create({
  baseURL: "/api/v1/transactions",
  headers: {
    "X-Requested-With": "XMLHttpRequest",
  },
});
const ApiPriceList = axios.create({
  baseURL: "/api/v1/priceList",
  headers: {
    "X-Requested-With": "XMLHttpRequest",
  },
});

const ApiPeriod = axios.create({
  baseURL: "/api/v1/period",
  headers: {
    "X-Requested-With": "XMLHttpRequest",
  }, 
});

const ApiUpload = axios.create({
  baseURL: "/api/v1/upload",
  headers: {
    "X-Requested-With": "XMLHttpRequest",
  }, 
});


const ApiDashboard = axios.create({
  baseURL: "/api/v1/dashboard",
  headers: {
    "X-Requested-With": "XMLHttpRequest",
  },  
})

export  {ApiUsers,ApiClients,ApiTransactions,ApiPriceList,ApiPeriod,ApiUpload,ApiDashboard};
