
import React from "react";
// reactstrap components
import {

  Modal,
  ModalBody,
  ModalHeader,
  Table,
} from "reactstrap";
import BadgeStatus from "../../components/BadgeStatus/BadgeStatus.jsx";

// core components
import moment from 'moment';

const ShowModal = (props) => {
    return (
    <Modal  isOpen= {props.isOpen} toggle={props.toggle} modalTransition= {{timeout: 100}} className="modal-default ">
    <ModalHeader toggle={props.toggle}>{props.title}</ModalHeader>
    <ModalBody>
    <Table className="align-items-center table-dark table-flush text-light" responsive>
         
            <tbody>
            <tr>
                <td>Type :</td>
                <td>{props.body.period.type.type}</td>
            </tr>
            <tr>
                <td>Email :</td>
                <td>{props.body.email}</td>
            </tr>
            <tr>
                <td>Client name :</td>
                <td>{props.body.clientName}</td>
            </tr>
            <tr>
                <td>Phone:</td>
                <td>{props.body.phone}</td>
            </tr>
            <tr>
                <td>Note:</td>
                <td>{props.body.note}</td>
            </tr>
            <tr>
                <td>Status :</td>
                <td><BadgeStatus value= {props.body.status} /></td>
            </tr>
            <tr>
                <td> Period :</td>
                <td>{props.body.period.period}</td>
            </tr>
            <tr>
                <td>Creation Date :</td>
                <td>{moment(props.body.starter_date).format("DD/MM/YYYY")}</td>
            </tr>
            {props.body.renewal_date &&
                    <tr>
                    <td>Renewal Date :</td>
                    <td>{moment(props.body.renewal_date).format("DD/MM/YYYY")}</td>
                </tr>
        }
    
            <tr>
                <td>Expiration Date :</td>
                <td>{moment(props.body.end_date).format("DD/MM/YYYY")}</td>
            </tr>
    
            <tr>
                <td>Username :</td>
                <td>{props.body.username}</td>
            </tr>
            <tr>
                <td>Password :</td>
                <td>{props.body.password}</td>
            </tr>
       
            </tbody>
        </Table>
    
    </ModalBody>
  </Modal>
    )
 
  }


export default ShowModal;
