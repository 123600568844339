
import React from "react";
import { Link , useHistory } from 'react-router-dom';
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Navbar,
  Nav,
  Container,
  Media
} from "reactstrap";

import { connect } from "react-redux";
import  { logout } from "../../actions/auth";
import { withRouter } from "react-router-dom";

const AdminNavbar = (props) =>  {

  const history = useHistory();

  async function handleLogout() {
    await props.logout();
    
    history.push("/auth/login");
  }
  
  return (
      <>
        <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
          <Container fluid>
            <Link
              className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
            >
              {props.brandText}
            </Link>
     
            <Nav className="align-items-center d-none d-md-flex" navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle className="pr-0" nav>
                  <Media className="align-items-center">
                    <span className="avatar avatar-sm rounded-circle">
                      <img
                        alt="..."
                        src= {props.userInfo.photo  ? "img/users/"+props.userInfo.photo : "/img/users/default.jpg"} 
                      />
                    </span>
                    <Media className="ml-2 d-none d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold">
                          {props.userInfo.name}
                      </span>
                    </Media>
                  </Media>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow" right>
                  <DropdownItem className="noti-title" header tag="div">
                    <h6 className="text-overflow m-0">Welcome!</h6>
                  </DropdownItem>
                  <DropdownItem to="/admin/user-profile" tag={Link}>
                    <i className="ni ni-single-02" />
                    <span>My profile</span>
                  </DropdownItem>
                  <DropdownItem >
                    <i className="ni ni-cart" />
                   Credit :  <span>{props.userInfo.credit}</span>
                  </DropdownItem>
                  {/* <DropdownItem to="/admin/user-profile" tag={Link}>
                    <i className="ni ni-calendar-grid-58" />
                    <span>Activity</span>
                  </DropdownItem>
                  <DropdownItem to="/admin/user-profile" tag={Link}>
                    <i className="ni ni-support-16" />
                    <span>Support</span>
                  </DropdownItem> */}
                  <DropdownItem divider />
                  <DropdownItem onClick={handleLogout} tag={Link}>
                    <i className="ni ni-user-run" />
                    <span>Logout</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Container>
        </Navbar>
      </>
    );
  }

  const mapStateToProps = (state) => {
    return {
      isLogged : state.auth.isLogged, 
      role : state.auth.role , 
      email : state.auth.email,
      userInfo : state.auth.userInfo ,
      error : state.error , 
    }
  }
  export default withRouter (connect(mapStateToProps,{logout})(AdminNavbar) ) ; 
