import { combineReducers } from "redux";
import alertReducer from "./alertReducer";

import authReducer from "./authReducer";
import clientReducer from "./clientsReducer";
import errorReducer from "./errorReducer";
//import usersReducer from "./usersReducer";

export default combineReducers({
  auth: authReducer,
  error: errorReducer,
  alert : alertReducer,
  clients : clientReducer,
  // users: usersReducer,
});
