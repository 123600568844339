
import React, { useState } from "react";
// reactstrap components
import {

  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  FormGroup,
  Col,
  Row,
  Input,
  Form
} from "reactstrap";
import { store } from 'react-notifications-component';


// core components
import {ApiUsers} from "../../services" ;

const ChangePassword = (props) => {


 

    const [inputs , setInputs] = useState ({
        passwordCurrent : '',
        passwordConfirm : '' ,
        password : ''

       
       },[])
         function handleChange(e) {
           const { name, value } = e.target;
           setInputs(inputs => ({ ...inputs, [name]: value }));
           }
        
           async function handleSubmit(e) {
             e.preventDefault();
            try {
              props.toggle();
              await ApiUsers.patch('/updateMyPassword',inputs).then(res => {
               if (res.data.status === 'success')
               {
                store.addNotification({
                  title: "Success",
                  message : "Password changed successfully!",
                  type: "success",
                  insert: "top",
                  container: "top-right",
                  animationIn: ["animate__animated", "animate__fadeIn"],
                  animationOut: ["animate__animated", "animate__fadeOut"],
                  dismiss: {
                    duration: 2000,
                  }
                });
               }else {
                store.addNotification({
                  title: "Error",
                  message : "Your password confirmation are not the same!",
                  type: "danger",
                  insert: "top",
                  container: "top-right",
                  animationIn: ["animate__animated", "animate__fadeIn"],
                  animationOut: ["animate__animated", "animate__fadeOut"],
                  dismiss: {
                    duration: 2000,
                  }
                });
               }
              });
                    
              }catch(e){
                store.addNotification({
                  title: "Error",
                  message : "Validation error!",
                  type: "danger",
                  insert: "top",
                  container: "top-right",
                  animationIn: ["animate__animated", "animate__fadeIn"],
                  animationOut: ["animate__animated", "animate__fadeOut"],
                  dismiss: {
                    duration: 2000,
                  }
                });
               }
          }
        
    return (
      <>
    <Modal isOpen= {props.isOpen} toggle={props.toggle} modalTransition= {{timeout: 100}}>
                      <Form role="form"  onSubmit={handleSubmit}>

    <ModalHeader toggle={props.toggle}>{props.title}</ModalHeader>
    <ModalBody>

                      <Row>
                
                        <Col lg="8">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Current password
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-email"
                              type="password"
                              placeholder="Current password"
                              name="passwordCurrent"
                              onChange= {handleChange}
                              required
                            />
                          </FormGroup>
                        </Col>
             
                      </Row>
                      <Row>
                      <Col lg="8">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              New password
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-first-name"
                              placeholder="New password"
                              type="password"
                              name="password"
                              onChange= {handleChange}
                              required
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                     <Row>
                     <Col lg="8">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-phone"
                            >
                              Confirm password
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-first-name"
                              placeholder="Confirm password"
                              type="password"
                              name="passwordConfirm"
                              onChange= {handleChange}
                              required
                            />
                          </FormGroup>
                        </Col>
                     </Row>
    </ModalBody>
    <ModalFooter>
      <Button color="primary" type="submit">Save</Button>{' '}
      <Button color="secondary" onClick={props.toggle}>Cancel</Button>
    </ModalFooter>
    </Form>

  </Modal>

  </>
    )
 
  }

  export default ChangePassword;