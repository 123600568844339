
import React, { useState } from "react";
import {

    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,

  } from "reactstrap";
  import ShowModal from './../../components/ModalAccounts/Show.jsx';
  import EditModal from './../../components/ModalAccounts/Edit.jsx';
  import DeleteModal from './../../components/ModalAccounts/Delete.jsx';
  import ReneweModal from './../../components/ModalAccounts/Renew.jsx' ; 
const Actions =(props) => {
    const [showModal, setShowModal] = useState(false);
    const toggleShow = () => setShowModal(!showModal);

    const [editModal, setEditModal] = useState(false);
    const toggleEdit = () => setEditModal(!editModal);

    const [deleteModal, setDeleteModal] = useState(false);
    const toggleDelete = () => setDeleteModal(!deleteModal);

    const [renewModal, setRenewModal] = useState(false);
    const toggleRenew = () => setRenewModal(!renewModal);
    return(<>
 <UncontrolledDropdown >
   <DropdownToggle
     className="btn-icon-only text-light"
     href={e => e.preventDefault()}
     role="button"
     data-toggle="dropdown"
     data-boundary="window"
     aria-haspopup="true"
      aria-expanded="false"
     size="sm"
     color=""
     onClick={e => e.preventDefault()}
   >
     <i className="fas fa-ellipsis-v" />
   </DropdownToggle>
   <DropdownMenu className="dropdown-menu-arrow" right>
     <DropdownItem
     href={toggleShow}
       onClick={toggleShow}
       className="btn"
     >
       Show
     </DropdownItem>
     <DropdownItem
       href={toggleEdit}
       onClick={toggleEdit}
       className="btn"

     >
       Edit
     </DropdownItem>
     <DropdownItem
       href={toggleRenew}
       onClick={toggleRenew}
       className="btn"

     >
       Renew
     </DropdownItem>
     {/* {props.id.row.original.status === 'in progress'  ?  
     <DropdownItem
       href={toggleDelete}
       onClick={toggleDelete}
       className="btn"

     >
       Delete
     </DropdownItem>
: <></>
}      */}
    {/* <DropdownItem        className="btn">
    <a href = {"files/"+props.id.row.original.file} download>Donwload File</a>
    </DropdownItem> */}
   </DropdownMenu>
 </UncontrolledDropdown>


 
        <ShowModal title = 'Account info' body = {props.id} isOpen= {showModal} toggle={toggleShow} >
        </ShowModal>
        <EditModal  title = 'Edit account' body = {props.id} isOpen= {editModal} toggle={toggleEdit} >
        </EditModal>
          <DeleteModal title = 'Delete account' body = {props.id} isOpen= {deleteModal} toggle={toggleDelete} >
        </DeleteModal> 
        <ReneweModal title = 'Renew account' body = {props.id} isOpen= {renewModal} toggle={toggleRenew} />



</>


    )
}
export default Actions ;