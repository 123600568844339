
import React, { useState, useEffect } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  } from "reactstrap";
// core components
import Header from "components/Headers/Header.jsx";
import { useHistory } from 'react-router-dom';
import {ApiUsers} from "services";
import { store } from 'react-notifications-component';
import { ApiUpload } from "../../../services";


const CreateUser = () => {
  useEffect(() => {
    //fetchProfile(props);
  },[])


  const history = useHistory();
  let formData = new FormData();

const [inputs , setInputs] = useState ({
 name : '',
 email : '' ,
 phone : '',
 adress : '',
 role : 'admin', 
 credit : 0 , 
 password : '' ,
 passwordConfirm : '',
 photo : 'default.jpg'

},[])


  function handleChange(e) {
    const { name, value } = e.target;
    setInputs(inputs => ({ ...inputs, [name]: value }));
    }

    const handleClick = () => {
      history.push("/admin/users");
  }

    async function handleSubmit(e) {
      e.preventDefault();
      if (formData.has('photo'))
      {
      const res = await ApiUpload.post('image',formData); 
      const  file =  res.data.file ; 
      console.log(res)
      setInputs(inputs.photo = file );
      }
    
    try {
       await ApiUsers.post('/signup',inputs); 
       store.addNotification({
        title: "Success",
        message : "User account has ben created !",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 2000,
        }
      });
       history.push({pathname : "/admin/users"});
        }catch(e){
          store.addNotification({
            title: "Error",
            message : "Error during creation user account !",
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            }
          });
        }
   }

  return (
      <>
            <Header/>
        {/* Page content */}
        <Container className="mt--7" fluid>
        <Form role="form" onSubmit={handleSubmit}>

        <Row>

          <div className="col">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-secondary border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Create new user</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                      <Button
                        color="primary"
                        onClick={handleClick}
                        size="sm"
                      >
                        Back to users list
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                    <h6 className="heading-small text-muted mb-4">
                      User information
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Email address
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-email"
                              type="email"
                              name="email"
                              placeholder="Email adress"
                              onChange= {handleChange}
                              required
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              name
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-first-name"
                              placeholder="First name"
                              type="text"
                              name="name"
                              onChange= {handleChange}
                              required
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                       
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-phone"
                            >
                              phone
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-first-name"
                              placeholder="phone"
                              type="phone"
                              name="phone"
                              onChange= {handleChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-adress"
                            >
                              adress
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-adress"
                              name="adress"
                              placeholder="adress"
                              type="text"
                              onChange= {handleChange}
                            />
                          </FormGroup>
                        </Col>
             
                      </Row>
                    </div>
                  
                    <hr className="my-4" />
                    {/* Address */}
                    <h6 className="heading-small text-muted mb-4">
                      Contact information
                    </h6>
                    <div className="pl-lg-4">
                    <Row>
                
                <Col lg="6">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-email"
                    >
                      Role
                    </label>
                    <Input
                      className="form-control-alternative"
                      id="input-email"
                      type="select"
                      name="role"
                      onChange= {handleChange}
                    >
                      <option>admin</option>
                      <option>user</option>
                      <option>dealer</option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-first-name"
                    >
                      Credit
                    </label>
                    <Input
                      className="form-control-alternative"
                      id="input-first-name"
                      type="number"
                      name="credit"
                      onChange= {handleChange}
                      disabled
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
               
                <Col lg="6">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-phone"
                    >
                      Password
                    </label>
                    <Input
                      className="form-control-alternative"
                      id="input-first-name"
                      placeholder="Password"
                      type="password"
                      name="password"
                      onChange= {handleChange}
                      required
                    />
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-adress"
                    >
                      Confirm password
                    </label>
                    <Input
                      className="form-control-alternative"
                      id="input-adress"
                      name="passwordConfirm"
                      placeholder="Confirm password"
                      type="password"
                      onChange= {handleChange}
                      required
                    />
                  </FormGroup>
                </Col>
     
              </Row>
              <br></br>
              <Button className="btn btn-success btn-lg btn-block" type="submit">Create</Button>

            </div>
                </CardBody>
              </Card>
</div>

          </Row>
          </Form>

        </Container>
      </>
    );
  }

  export default CreateUser ;
