
import React from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import {
  chartOptions,
  parseOptions,
} from "variables/charts.jsx";

import DashboardHeader from "components/Headers/DashboardHeader.jsx";
import { ApiDashboard } from "../services";
// Colors
var colors = {
  gray: {
    100: "#f6f9fc",
    200: "#e9ecef",
    300: "#dee2e6",
    400: "#ced4da",
    500: "#adb5bd",
    600: "#8898aa",
    700: "#525f7f",
    800: "#32325d",
    900: "#212529"
  },
  theme: {
    default: "#172b4d",
    primary: "#5e72e4",
    secondary: "#f4f5f7",
    info: "#11cdef",
    success: "#2dce89",
    danger: "#f5365c",
    warning: "#fb6340"
  },
  black: "#12263F",
  white: "#FFFFFF",
  transparent: "transparent"
};




class Index extends React.Component {
  state = {
    activeNav: 1,
    chartExample1Data: "data1",
    data : {} ,
    chartSale : {}
  };
  toggleNavs = (e, index) => {
    e.preventDefault();
    this.setState({
      activeNav: index,
      chartExample1Data:
        this.state.chartExample1Data === "data1" ? "data2" : "data1",
    });
    let wow = () => {
    };
    wow.bind(this);
    setTimeout(() => wow(), 1000);
    // this.chartReference.update();
  };
  componentWillMount() {
    if (window.Chart) {
      ApiDashboard.get('/dashboardAdmin').then((res) => {
        this.setState({data : res.data.data}); 
        this.setState({chartSale : {
          options: {
            scales: {
              yAxes: [
                {
                  gridLines: {
                    color: colors.gray[900],
                    zeroLineColor: colors.gray[900]
                  },
                  ticks: {
                    callback: function(value) {
                      if (!(value % 10)) {
                        return value ;
                      }
                    }
                  }
                }
              ]
            },
            tooltips: {
              callbacks: {
                label: function(item, data) {
                  var label = data.datasets[item.datasetIndex].label || "";
                  var yLabel = item.yLabel;
                  var content = "";
        
                  if (data.datasets.length > 1) {
                    content += label;
                  }
        
                  content +=  yLabel ;
                  return content;
                }
              }
            }
          },
          data1: canvas => {
            return {
              labels: ["Jan" ,"Feb","Mar","Apr","May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
              datasets: [
                {
                  label: "Performance",
                  data: res.data.data.transactionsAccountByMonths
                }
              ]
            };
          },
          data2: canvas => {
            return {
              labels: ["Jan" ,"Feb","Mar","Apr","May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
              datasets: [
                {
                  label: "Performance",
                  data: res.data.data.transactionsCreditByMonths
                }
              ]
            };
          }
        }})
      });
      parseOptions(Chart, chartOptions());
    }
  }


  
  render(
 


  ) {
    return (
      <>
        <DashboardHeader data = {this.state.data}/>
        {/* Page content */}
        <Container className="mt--7" fluid>


          
          <Row>
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="bg-gradient-default shadow">
                <CardHeader className="bg-transparent">
                  <Row className="align-items-center">
                    <div className="col">
                      <h6 className="text-uppercase text-light ls-1 mb-1">
                        Overview
                      </h6>
                      <h2 className="text-white mb-0">Sales value</h2>
                    </div>
                    <div className="col">
                      <Nav className="justify-content-end" pills>
                        <NavItem>
                          <NavLink
                            className={classnames("py-2 px-3", {
                              active: this.state.activeNav === 1,
                            })}
                              
                            onClick={(e) => this.toggleNavs(e, 1)}
                          >
                            <span className="d-none d-md-block">Accounts</span>
                            <span className="d-md-none">M</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames("py-2 px-3", {
                              active: this.state.activeNav === 2,
                            })}
                            data-toggle="tab"
                              
                            onClick={(e) => this.toggleNavs(e, 2)}
                          >
                            <span className="d-none d-md-block">Credits</span>
                            <span className="d-md-none">W</span>
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  {/* Chart */}
                  <div className="chart">
                    <Line
                      data={this.state.chartSale[this.state.chartExample1Data]}
                      options={this.state.chartSale.options}
                      getDatasetAtEvent={(e) => console.log(e)}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
            
          </Row>





          <Row className="mt-5">
          <Col xl="6">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">All accounts</h3>
                    </div>
                    <div className="col text-right">
                        {this.state.data.accounts}
                    </div>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Status</th>
                      <th scope="col">Accounts </th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                 <tr>
                      <th  scope="row">
                        Actif 
                        </th>
                        <td>
                          {this.state.data.actifAccounts}
                        </td>
                        <td>
                        <div className="d-flex align-items-center">
                          <span className="mr-2">{Math.floor((this.state.data.actifAccounts / this.state.data.accounts) * 100 ) }%</span>
                          <div>
                            <Progress
                              max="100"
                              value={Math.floor((this.state.data.actifAccounts / this.state.data.accounts) * 100 ) }
                              barClassName="bg-gradient-success"
                            />
                          </div>
                        </div>
                      </td>
                      </tr>
                    
                      <tr>
                      <th  scope="row">
                        Disabled 
                        </th>
                        <td>
                          {this.state.data.disabledAccounts}
                        </td>
                        <td>
                        <div className="d-flex align-items-center">
                          <span className="mr-2">{Math.floor((this.state.data.disabledAccounts / this.state.data.accounts) * 100 ) }%</span>
                          <div>
                            <Progress
                              max="100"
                              value={Math.floor((this.state.data.disabledAccounts / this.state.data.accounts) * 100 ) }
                              barClassName="bg-gradient-danger"
                            />
                          </div>
                        </div>
                      </td>
                      </tr> 
                      
                      <tr>
                      <th  scope="row">
                        In progress 
                        </th>
                        <td>
                          {this.state.data.inProgressAccounts}
                        </td>
                        <td>
                        <div className="d-flex align-items-center">
                          <span className="mr-2">{Math.floor((this.state.data.inProgressAccounts / this.state.data.accounts) * 100 ) }%</span>
                          <div>
                            <Progress
                              max="100"
                              value={Math.floor((this.state.data.inProgressAccounts / this.state.data.accounts) * 100 ) }
                              barClassName="bg-gradient-warning"
                            />
                          </div>
                        </div>
                      </td>
                      </tr>
                  </tbody>
                </Table>
              </Card>
            </Col>
            <Col xl="6">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Best Dealers</h3>
                    </div>
                    <div className="col text-right">
                      <Button
                        color="primary"
                          
                        onClick={(e) => e.preventDefault()}
                        size="sm"
                      >
                        See all
                      </Button>
                    </div>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Accounts </th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.data.bestDealers ? this.state.data.bestDealers.map((obj) => {
                     return( <tr>
                        <th  scope="row">
                        {obj.name}
                        </th>
                        <td>
                          {obj.count}
                        </td>
                        <td>
                        <div className="d-flex align-items-center">
                          <span className="mr-2">{Math.floor((obj.count / this.state.data.actifAccounts) * 100 ) }%</span>
                          <div>
                            <Progress
                              max="100"
                              value={Math.floor((obj.count / this.state.data.actifAccounts) * 100 ) }
                              barClassName="bg-gradient-danger"
                            />
                          </div>
                        </div>
                      </td>
                      </tr>)
                    }) : ''}
                  </tbody>
                </Table>
              </Card>
            </Col>
      
          </Row>


          <Row className="mt-5">
          <Col xl="6">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Accounts By Type</h3>
                    </div>
                    <div className="col text-right">
                      <Button
                        color="primary"
                          
                        onClick={(e) => e.preventDefault()}
                        size="sm"
                      >
                        See all
                      </Button>
                    </div>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Type</th>
                      <th scope="col">Accounts </th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.data.accountType ? this.state.data.accountType.map((obj) => {
                     return( <tr>
                        <th  scope="row">
                        {obj.type}
                        </th>
                        <td>
                          {obj.count}
                        </td>
                        <td>
                        <div className="d-flex align-items-center">
                          <span className="mr-2">{Math.floor((obj.count / this.state.data.actifAccounts) * 100 ) }%</span>
                          <div>
                            <Progress
                              max="100"
                              value={Math.floor((obj.count / this.state.data.actifAccounts) * 100 ) }
                              barClassName="bg-gradient-success"
                            />
                          </div>
                        </div>
                      </td>
                      </tr>)
                    }) : ''}
                  </tbody>
                </Table>
              </Card>
            </Col>
            <Col xl="6">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Accounts By Period</h3>
                    </div>
                    <div className="col text-right">
                      <Button
                        color="primary"
                          
                        onClick={(e) => e.preventDefault()}
                        size="sm"
                      >
                        See all
                      </Button>
                    </div>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Accounts </th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.data.accountPeriod ? this.state.data.accountPeriod.map((obj) => {
                     return( <tr>
                        <th  scope="row">
                        {obj.period}
                        </th>
                        <td>
                          {obj.count}
                        </td>
                        <td>
                        <div className="d-flex align-items-center">
                          <span className="mr-2">{Math.floor((obj.count / this.state.data.actifAccounts) * 100 ) }%</span>
                          <div>
                            <Progress
                              max="100"
                              value={Math.floor((obj.count / this.state.data.actifAccounts) * 100 ) }
                              barClassName="bg-gradient-blue"
                            />
                          </div>
                        </div>
                      </td>
                      </tr>)
                    }) : ''}
                  </tbody>
                </Table>
              </Card>
            </Col>
      
          </Row>
         
        </Container>
      </>
    );
  }
}

export default Index;
