
import React, { useState, useEffect } from "react";
// reactstrap components
import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  Button,
  CardBody,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,

} from "reactstrap";
// core components
import Header from "components/Headers/Header.jsx";
import { ApiPriceList , ApiClients} from "../../services" ;
import moment from 'moment';
import Actions from "../../components/tables/ActionsAdmin";

import CreateModal from './../../components/ModalAccountsAdmin/Create.jsx'
import { CSVLink } from "react-csv";
import {format} from 'date-fns' ; 
import InfiniteScroll from 'react-infinite-scroll-component';
import BadgeStatus from './../../components/BadgeStatus/BadgeStatus' ; 
import { store } from 'react-notifications-component';




const AccountsAdmin = () => {



    const [filter,setFilter] = useState(false);

  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(10);
  const [sort,setSort] = useState(null);
  const [data,setData]= useState([]);
  const [hasMore,setHasMore] = useState(true);
  const [length,setLength]= useState(0);
 
  const headers = [
    { label: "type", key: "type" },
    { label: "period", key: "period" },
    { label: "client", key: "client" },
    { label: "email", key: "email" },
    { label: "phone", key: "phone" },
    { label: "note", key: "note" },
    { label: "status", key: "status" },
    { label: "creation_date", key: "creation_date" },
    { label: "renewal_date", key: "renewal_date" },
    { label: "end_date", key: "end_date" },
    { label: "username", key: "username" },
    { label: "password", key: "password" }

  ];

  let csv = []; 
  const [csvData,setCsvData] = useState ([]) ; 



  useEffect(()=>{
     ApiClients.get('',{params : {sort , page ,   limit , filter }}).then((res) => {setData(res.data.data.data);setLength(res.data.length) }) ;
  },[]);

  useEffect(()=>{
    if (data.length != 0)
    {
      data.map(async (d) => {
        csv.push({
          'type' : d.period.type.type , 
          'period' : d.period.period ,
           'client' : d.clientName , 
            'email' : d.email , 
            'phone' : d.phone , 
            'note' : d.note ,
            'status' : d.status , 
            'creation_date' : convertDate(d.starter_date) , 
            'renewal_date' : convertDate(d.renewal_date) , 
            'end_date' : convertDate(d.end_date) , 
          'username' : d.username , 
          'password' : d.password
        });  
      });
      setCsvData(csv);
    }
  },[data]) ; 




     async function fetchAbonnementType () {
      const  res =  await ApiPriceList.get() ;
          const priceList = res.data.data.data ;
         const periods =  await fetchPeriods(priceList[0]);
         setState2({periods,priceList});
       }
     async function fetchPeriods (priceList)
      {
        const res = await ApiPriceList.get(priceList.id)
          const periods = res.data.data.data.periods ; 
          // const period = periods[0];
          // setInputs({...inputs,period})
          return periods;
      } 
      
      const [state2 , setState2] = useState ({
        priceList :[],
        periods : []
      });
      

  const [createModal, setCreateModal] = useState(false);
    const toggleCreate = async () => 
    {
        if (!createModal)    await fetchAbonnementType();  
      setCreateModal(!createModal);
    
    }

    function setPeriods (periods){
      setState2({...state2,periods});

  }



  function convertDate ( date )
  {
    return moment(date).format("DD/MM/YYYY");

  }

  const fetchMoreData = () => {

    if (data.length >= length) {
      setHasMore(false);
      return;
    }
 
    setPage(page+1)
        ApiClients.get('',{params : {sort , page : page+1 ,   limit , filter }}).then((res) => {const d = data ;const d2 = d.concat(res.data.data.data) ; setData(d2) ;  }) ;
  };


  const onSearchChange = async(e) => {
    setPage(1);
    setHasMore(true);
    if (e.target.value.length === 0)
    {
      setFilter(false);
      ApiClients.get('',{params : {sort , page : 1 ,   limit , filter : false}}).then((res) => {setData(res.data.data.data);setLength(res.data.length) }) ;

    }
    else
    {
      setFilter(e.target.value)
      ApiClients.get('',{params : {sort , page : 1,   limit , filter: e.target.value }}).then((res) => {setData(res.data.data.data);setLength(res.data.length) }) ;
    }
 

  }


  const statusChecked = (status) => {
    return (status == 'enabled') ; 
    }

  const changeStatus = (user) => {
    if (user.status == 'enabled')
    {
      ApiClients.post('/disable_account',{client: user._id}).then((res,err)=> {
        if (res.status == 200)
        {
          // setStatus( 'disabled')
          store.addNotification({
            title: "Account disabled",
            message : ' ',
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 4000,
            }
          });
        }
      });

    }
    else 
    ApiClients.post('/enable_account',{client: user._id}).then((res,err)=> {
      if (res.status == 200)
      {
        // setStatus('enabled');
        store.addNotification({
          title: "Account enabled",
          message : ' ',
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 4000,
          }
        });
      }
    });

}

    return (
      <>
        <Header />
        {/* Page content */}

        <Container  className="mt--7 " fluid>
          {/* Table */}
          <Row >
            
            <div className="col" >
            <Card className="bg-default">
      
            <CardHeader className="bg-transparent border-0">
                  <Row className="align-items-center">
                    <Col sm={"6"} md={3} >
                      <h3  className="text-white mb-0">All accounts  {length}</h3>
                    </Col>
                    <Col sm= {"6"} md={6} >
                    <Form className=" navbar-search-secondary ">
              <FormGroup className="mb-0">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend"  >
                    <InputGroupText className="bg-secondary">
                      <i className="fas fa-search" />
                     </InputGroupText>
                  </InputGroupAddon>
                  <Input className="bg-secondary" placeholder="Search" type="text" onChange={onSearchChange} />
                </InputGroup>
              </FormGroup>
            </Form>
                    </Col>
                    <Col className="text-center" sm={"6"} md={3} >
                      <Button
                        color="primary"
                        onClick={toggleCreate}
                      >
                        Create new account
                      </Button>
                    </Col >
                    <Col className="text-center" sm={"6"} xs={"12"} md ={2}>
                 
                 <CSVLink data={csvData} headers= {headers}>
                 <Button
                   color="secondary"
                   size="sm"
                 >
               Download csv
                 </Button>
                   </CSVLink>

               </Col>
                  </Row>
                 
                </CardHeader>




                <InfiniteScroll
          dataLength={data.length}
          next={() =>{fetchMoreData()}}
          hasMore={hasMore}
          loader={                      <Col className="text-center" >
     <h3  className="text-white mb-0 ">Loading...</h3>
        </Col>  
        }
          endMessage={
              <Col className="text-center" >
     <h3  className="text-white mb-0">Yay! You have seen it all</h3>
        </Col>  
          }
        >


{ data.map((user)=>{


return (
  <Card className="bg-default">

  <CardBody>
    <Row className="align-items-center" >
      <Col className="col-auto">
  
      
      <a
          className="avatar avatar-xl rounded-circle"
          href="#pablo"
          onClick={e => e.preventDefault()}
        >
      <img alt='...' src = {"img/users/"+user.user.photo}/>
      </a>
      </Col>
      <div className="col ml--2">
        <h4 className="text-white mb-0" >
           {' '+user.username}  
        </h4>
    
        <h3 className="text-light text-sm text-muted mb-0">create: {user.starter_date&& ' ' +format(new Date(user.starter_date) , 'dd/MM/yyyy')}</h3>
        <span className="text-light" >●</span>
        <small className="text-light">Created by: {' '+user.user.name}</small>

      </div>
      <div className="col ml--2">
     
  
        <h3 className="text-light text-sm text-muted mb-0">expire: { user.end_date && ' '+format(new Date(user.end_date) , 'dd/MM/yyyy')}</h3>
        
    
      </div>
      <Col className="col-auto">
      {user.status === 'enabled' ?      <Button color="success" size="sm" type="button">
          {user.period.type.type+'  ' + user.period.period}
        </Button>
        : 
        <Button color="danger" size="sm" type="button">
        {user.period.type.type+'  ' + user.period.period}
      </Button>
      }

      </Col>
      <Col className="col-auto">
      {/* <Switch id = {user} /> */}
      <div>

            
            <label class="custom-toggle">
            <input type="checkbox"  checked = {statusChecked(user.status)} onClick={() => changeStatus(user)}/>
            <span
            class="custom-toggle-slider rounded-circle"
            data-label-off="No"
            data-label-on="Yes"
            value='eee'
            ></span>
            </label>
            <div>  
              <BadgeStatus value= {user.status}/></div>



            </div>
   
      </Col>
      <Col className="col-auto">
        <Actions id = {user} />

      </Col>
    </Row>
  </CardBody>
  </Card>    

)
                })}

</InfiniteScroll>


              </Card>
            </div>
          </Row>
        </Container>



        <CreateModal setPeriods={setPeriods} periods= {state2.periods} priceLists = {state2.priceList} title = 'Create new account' isOpen= {createModal} toggle={toggleCreate} >
        </CreateModal>


      </>
    );
  }

  
  export default AccountsAdmin ; 
  

