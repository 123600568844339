
import React from "react";
// reactstrap components
import {

  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Form,
  
} from "reactstrap";

// core components
import {ApiClients} from "../../services" ;
import { store } from 'react-notifications-component';
import useSwr, {mutate, trigger} from "swr";


const DeleteModal = (props) => {


           async function handleSubmit() {
            props.toggle();
            try {
              const url = 'http://itautronic.com/api/v1/clients'
             mutate(url) ; 
             await ApiClients.delete(props.body._id);
             trigger(url)
           store.addNotification({
            title: "Success",
            message : "Account has ben deleted!",
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            }
          });
        }catch(e){
          store.addNotification({
            title: "Error",
            message : "Validation error",
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            }
          });
        }
          }

          
         
    return (<>
    <Modal isOpen= {props.isOpen} toggle={props.toggle} modalTransition= {{timeout: 100}}>
                      <Form role="form"  >

    <ModalHeader toggle={props.toggle}>{props.title}</ModalHeader>
    <ModalBody>
    you sure you want to delete this account !!    
    </ModalBody>
    <ModalFooter>
      <Button color="primary" onClick={handleSubmit}>Delete</Button>{' '}
      <Button color="secondary" onClick={props.toggle}>Cancel</Button>
    </ModalFooter>
    </Form>

  </Modal>

 </>
    )
 
  }

  
  export default DeleteModal ; 
  

