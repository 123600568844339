
import React, { useState } from "react";
import { useLocation , useHistory } from 'react-router-dom';
//import {history} from "../helpers/history";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col
} from "reactstrap";
import { connect } from "react-redux";
import  { login } from "../../actions/auth";
import { withRouter } from "react-router-dom";



const Login = (props) => {
 
 
  const history = useHistory();

  const location = useLocation();

      const [inputs, setInputs] = useState({
        email: '',
        password: ''
      });
      const [submitted, setSubmitted] = useState(false);
      const { email, password } = inputs;
      
  

      function handleChange(e) {
      const { name, value } = e.target;
      setInputs(inputs => ({ ...inputs, [name]: value }));
      }
      
      async function handleSubmit(e) {
      e.preventDefault();
      try {
      setSubmitted(true); 
      if (email && password) {
          // get return url from location state or default to home page
           const user  = await props.login(inputs); 
           let path = ''
           if (user.payload.role == 'admin')
           {
              path = "/admin/index"
           }else 
           {
              path = "/admin/dashboard-user"
           }

           const { from } = location.state || { from: { pathname: path } };
           history.push(from);
    }
  }catch(e){

  }
      }
 
      return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent pb-5">
            {/* <div className="text-muted text-center mt-2 mb-3">
              <small>Sign in with</small>
            </div>
            <div className="btn-wrapper text-center">
              <Button
                className="btn-neutral btn-icon"
                color="default"
                  
                onClick={e => e.preventDefault()}
              >
                <span className="btn-inner--icon">
                  <img
                    alt="..."
                    src={require("assets/img/icons/common/github.svg")}
                  />
                </span>
                <span className="btn-inner--text">Github</span>
              </Button>
              <Button
                className="btn-neutral btn-icon"
                color="default"
                  
                onClick={e => e.preventDefault()}
              >
                <span className="btn-inner--icon">
                  <img
                    alt="..."
                    src={require("assets/img/icons/common/google.svg")}
                  />
                </span>
                <span className="btn-inner--text">Google</span>
              </Button>
            </div> */}
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <small>Or sign in with credentials</small>
            </div>
            <Form role="form"  onSubmit={handleSubmit}>
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input placeholder="Email" type="email" name="email" value={email} onChange={handleChange} className={'form-control' + (submitted && !email ? ' is-invalid' : '')} />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input placeholder="Password" type="password" name="password" value={password} onChange={handleChange} className={'form-control' + (submitted && !password ? ' is-invalid' : '')} />
                </InputGroup>
              </FormGroup>
              { props.error.status === 'fail' ?
              <span className="text-danger font-weight-700">Incorrect email or password !</span> : '' }
              <div className="custom-control custom-control-alternative custom-checkbox">
                <input
                  className="custom-control-input"
                  id=" customCheckLogin"
                  type="checkbox"
                />
                <label
                  className="custom-control-label"
                  htmlFor=" customCheckLogin"
                >
                  <span className="text-muted">Remember me</span>
                </label>
              </div>
              <div className="text-center">
                <Button className="my-4" color="primary" type="submit">
                  Sign in
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
        <Row className="mt-3">
          <Col xs="6">
            <a
              className="text-light"
                
              onClick={e => e.preventDefault()}
            >
              <small>Forgot password?</small>
            </a>
          </Col>
          <Col className="text-right" xs="6">
            <a
              className="text-light"
                
              onClick={e => e.preventDefault()}
            >
              <small>Create new account</small>
            </a>
          </Col>
        </Row>
      </Col>
    </>);
    
}

const mapStateToProps = (state) => {
  return {
    isLogged : state.auth.isLogged, 
    role : state.auth.role , 
    email : state.auth.email,
    userInfo : state.auth.userInfo ,
    error : state.error , 
  }
}

export default withRouter (connect(mapStateToProps,{login})(Login) ) ; 
