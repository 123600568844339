



import DashboardUser from 'views/IndexUser.jsx';
import DashboarAdmin from "views/Index.jsx";
import Profile from "views/pages/Profile.jsx";
import Register from "views/pages/Register.jsx";
import Login from "views/pages/Login.jsx";

import Accounts from "./views/pages/Accounts.jsx";
import AccountsAdmin from "./views/pages/AccountsAdmin.jsx";

import AccountsInProgress from "./views/pages/AccountsInProgress.jsx";

import TransactionsSale from './views/pages/transactions/TransactionsSale.jsx';
import TransactionsPurchase from './views/pages/transactions/TransactionsPurchase.jsx';

import TransactionsAdmin from './views/pages/transactions/TransactionsAdmin.jsx';
import TransactionsPurchaseAdmin from './views/pages/transactions/TransactionsPurchaseAdmin.jsx';
import TransactionsSaleAdmin from './views/pages/transactions/TransactionsSaleAdmin.jsx';



import Users from "./views/pages/users/Users.jsx";
import PriceList from "./views/pages/PriceList.jsx";
import NotFound from "./views/pages/NotFound.jsx";

import CreateUser from './views/pages/users/CreateUser.jsx';
var routes = [
  {
    path: "/notFound",
    name : "not found",
    component: NotFound,
    layout: "/auth",
    invisible : true 
  },
  
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: DashboarAdmin,
    layout: "/admin",
    authority: ["admin"],
  },
 
  {
    path: "/dashboard-user",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: DashboardUser,
    layout: "/admin",
    authority: ["dealer","user"],
  },

  {
    path: "/user-profile",
    name: "Profile",
    icon: "ni ni-single-02 text-yellow",
    component: Profile,
    layout: "/admin",
    authority: ["admin","dealer","user"],
  },

  {
    path: "/Accounts",
    name: "My accounts",
    icon: "ni ni-ui-04 text-blue",
    component: Accounts,
    layout: "/admin",
    authority: ["admin", "dealer" ],
  },
  {
    path: "/AccountsAdmin",
    name: "All Accounts",
    icon: "ni ni-planet text-blue",
    component: AccountsAdmin,
    layout: "/admin",
    authority: ["admin"],
  },
  // {
  //   path: "/AccountsAdminMobile",
  //   name: "mobile",
  //   icon: "ni ni-planet text-blue",
  //   component: AccountsAdminMobile,
  //   layout: "/admin",
  //   authority: ["admin"],
  // },
  {
    path: "/AccountInProgress",
    name: "Accounts in progress ",
    icon: "ni ni-app text-yellow",
    component: AccountsInProgress,
    layout: "/admin",
    authority: ["admin"],
  },
  {
    path: "/Users",
    name: "Users accounts",
    icon: "ni ni-circle-08 text-red",
    component: Users,
    layout: "/admin",
    authority: ["admin"],
  },

  {
    path: "/CreateUser",
    component: CreateUser,
    layout: "/admin",
    authority: ["admin"],
    invisible : true 
  },

  

  {
    path: "/TransactionsPurchaseAdmin",
    name: "Credit transactions",
    icon: "ni ni-credit-card text-green",
    component: TransactionsPurchaseAdmin,
    layout: "/admin",
    authority: ["admin"],
  },
  {
    path: "/TransactionsSaleAdmin",
    name: "Abonnement sales",
    icon: "ni ni-money-coins text-orange",
    component: TransactionsSaleAdmin,
    layout: "/admin",
    authority: ["admin"],
  },
  {
    path: "/TransactionsSale",
    name: "Accounts sales transactions",
    icon: "ni ni-money-coins text-orange",
    component: TransactionsSale,
    layout: "/admin",
    authority: ["user","dealer"],
  },
  {
    path: "/TransactionsPurchase",
    name: "Credit purchase transactions",
    icon: "ni ni-credit-card text-green",
    component: TransactionsPurchase,
    layout: "/admin",
    authority: ["user","dealer"],
  },

  {
    path: "/priceList",
    name: "Price list",
    icon: "ni ni-bullet-list-67 text-black",
    component: PriceList,
    layout: "/admin",
    authority: ["admin", "dealer" , "user"],
  },
  {
    path: "/",
    component: DashboardUser,
    layout: "/admin",
    authority: ["admin"],
    invisible : true
  },
  {
    path: "/login",
    component: Login,
    layout: "/auth",
    invisible : true
  },
  {
    path: "/register",
    component: Register,
    layout: "/auth",
    invisible : true
  },




];
export default routes;
