
import React from "react";


// reactstrap components
import {Row, Col,} from "reactstrap";

const Footer = () => {


  const  currentDateTime =  new Date()

   

    return (
      <footer className="footer">
        <Row className="align-items-center justify-content-xl-between">
          <Col xl="6">
            <div className="copyright text-center text-xl-left text-muted">
    ©{currentDateTime.getFullYear()}{" "}
              <a
                className="font-weight-bold ml-1"
                href="#"
                rel="noopener noreferrer"
                target="_blank"
              >
                ITAUTOTRONIC
              </a>
            </div>
          </Col>

        
        </Row>
      </footer>
    );
  }

export default Footer;
