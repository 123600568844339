
import React, { useState } from "react";
// reactstrap components
import {

  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Table,
  Input,
  Form
} from "reactstrap";

// core components
import {ApiUsers} from "../../services" ;
import { store } from 'react-notifications-component';



const EditModal = (props) => {


 
    const [inputs , setInputs] = useState ({
      name : props.body.name,
      email : props.body.email ,
      phone : props.body.phone,
      adress : props.body.adress,
      role : props.body.role, 

       
       },[])
         function handleChange(e) {

           const { name, value } = e.target;
           setInputs(inputs => ({ ...inputs, [name]: value }));
           }
           
           function HandleClick (e)  {
            const { name, value } = e.target;
            setInputs(inputs => ({ ...inputs, [name]: value }));
          }

        
           async function handleSubmit() {
            props.toggle();
            try {
           await ApiUsers.patch(props.body._id,inputs);
           store.addNotification({
            title: "Success",
            message : "Account information has ben updated!",
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            }
          });
           props.Users();  
        }catch(e){
          store.addNotification({
            title: "Error",
            message : "Validation error!",
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            }
          });        
        }
          }

    return (
      <>
    <Modal isOpen= {props.isOpen} toggle={props.toggle} modalTransition= {{timeout: 100}}>
                      <Form role="form"  >

    <ModalHeader toggle={props.toggle}>{props.title}</ModalHeader>
    <ModalBody>
        <Table className="align-items-center table-flush" responsive>
         
            <tbody>
            <tr>
                <td>name :</td>
                <td><Input name='name' defaultValue={inputs.name} 
                                              onChange= {handleChange}

                /></td>
            </tr>
            <tr>
                <td>Email :</td>
                <td><Input name='email' defaultValue={inputs.email}
                                              onChange= {handleChange}

                /></td>
            </tr>
               
            <tr>
                <td>Phone:</td>
                <td><Input name='phone' defaultValue={inputs.phone}
                                              onChange= {handleChange}

                /></td>
            </tr>
            <tr>
                <td>Adress:</td>
                <td><Input name='adress' defaultValue={inputs.adress}
                                              onChange= {handleChange}

                /></td>
            </tr>
     
        
            <tr>
                <td> Role :</td>
                <td><Input name='role' type='select' defaultValue={inputs.role}
                                              onChange= {HandleClick}

                >
                      <option>admin</option>
                      <option>user</option>
                      <option>dealer</option>
                    </Input></td>
            </tr>     
            </tbody>
        </Table>
    
    </ModalBody>
    <ModalFooter>
      <Button color="primary" onClick={handleSubmit}>Update</Button>{' '}
      <Button color="secondary" onClick={props.toggle}>Cancel</Button>
    </ModalFooter>
    </Form>

  </Modal>

  </>
    )
 
  }


  export default EditModal ;
