
import React, { useState } from "react";
import {

    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,

  } from "reactstrap";
  import ShowModal from '../ModalUsers/Show.jsx';
  import EditModal from '../ModalUsers/Edit.jsx' ; 
  import EditCreditModal from '../ModalUsers/EditCredit.jsx'
  import DeleteModal from '../ModalUsers/Delete.jsx'


const ActionsUsers =(props) => {
    const [showModal, setShowModal] = useState(false);
    const toggleShow = () => setShowModal(!showModal);

    const [editModal, setEditModal] = useState(false);
    const toggleEdit = () => setEditModal(!editModal);

    const [editCreditModal, setEditCreditModal] = useState(false);
    const toggleEditCredit = () => setEditCreditModal(!editCreditModal);

    const [deleteModal, setDeleteModal] = useState(false);
    const toggleDelete = () => setDeleteModal(!deleteModal);


    return(<>
 <UncontrolledDropdown>
   <DropdownToggle
     className="btn-icon-only text-light"
     href={e => e.preventDefault()}
     role="button"
     size="sm"
     color=""
     onClick={e => e.preventDefault()}
   >
     <i className="fas fa-ellipsis-v" />
   </DropdownToggle>
   <DropdownMenu className="dropdown-menu-arrow" right>
     <DropdownItem
            className="btn"
     href={toggleShow}
       onClick={toggleShow}
     >
       Show
     </DropdownItem>
     <DropdownItem
            className="btn"
       href={toggleEdit}
       onClick={toggleEdit}
     >
       Edit
     </DropdownItem>

     <DropdownItem
            className="btn"
       href={toggleEditCredit}
       onClick={toggleEditCredit}
     >
       Edit credit
     </DropdownItem>

     {/* <DropdownItem        className="btn"
       href={toggleDelete}
       onClick={toggleDelete}
     >
       Delete
     </DropdownItem> */}
   </DropdownMenu>
 </UncontrolledDropdown>


 
        <ShowModal title = 'User info' body = {props.id.row.original} isOpen= {showModal} toggle={toggleShow} >
        </ShowModal>
        <EditModal Users = {props.Users} title = 'Edit User' body = {props.id.row.original} isOpen= {editModal} toggle={toggleEdit} >
        </EditModal>
        <EditCreditModal Users = {props.Users} title = 'Edit Credit' body = {props.id.row.original} isOpen= {editCreditModal} toggle={toggleEditCredit} >
        </EditCreditModal>
          {/* <DeleteModal Accounts = {props.Users} title = 'Delete User' body = {props.id.row.original} isOpen= {deleteModal} toggle={toggleDelete} >
        </DeleteModal>  */}
     


</>


    )
}
export default ActionsUsers ;