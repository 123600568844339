
import React, { useEffect, useState } from "react";
// reactstrap components
import {

  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Table,
  Input,
  Form
} from "reactstrap";

// core components
import {ApiTransactions,ApiPriceList,ApiPeriod} from "services" ;
import { store } from 'react-notifications-component';
import  {mutate, trigger} from "swr";

const CreateModal = (props) => {
  
  
  useEffect(() => {
    setInputs ({
      clientName: '',
      email : '' ,
      phone : '',
      note : '',
      status : 'in progress',
      period : props.periods[0],
      starter_date : Date.now() ,
      operation : 'sale'
    }) ; 
    setPrice(0);
 },[props]);

     const [inputs , setInputs] = useState ({
      clientName: '',
      email : '' ,
      phone : '',
      note : '',
      status : 'in progress',
      period : props.periods[0],
      starter_date : Date.now() ,
      operation : 'sale'
     },[]);

     const [price , setPrice] = useState(0);

         function handleChange(e) {
           const { name, value } = e.target;
           setInputs(inputs => ({ ...inputs, [name]: value }));
           }
           
          async function HandleClick (e)  {
            const { name, value } = e.target;
            setInputs(inputs => ({ ...inputs, [name]: value }));
           const  res = await ApiPeriod.get('/'+e.target.value);
            setPrice(res.data.data.data.price);
         
          }

        function onClickType (e)  {
  
          
          if (e.target.value !== 1)
          {
          ApiPriceList.get('/'+e.target.value).then(res => { 
          const periods = res.data.data.data.periods ;
          props.setPeriods(periods);
          const period = periods[0];
          setInputs({...inputs,period});
          setPrice(period.price);
          })
        } 
        }

        const [defaultValue,setDefaultValue] = useState('');
        useEffect(()=> {
          console.log(defaultValue);
          if (props.periods[0])
          setDefaultValue(props.periods[0]._id);
        },[props.periods])


           async function handleSubmit(e) {
             props.toggle();
             e.preventDefault();
             if (!inputs.period)
             setInputs(inputs.period = props.periods[0]);
            try {
              const url =  'http://itautronic.com/api/v1/clients/myAccounts' ; 
              mutate(url) ;
              await ApiTransactions.post('',inputs);
              trigger(url);

              store.addNotification({
                title: "Account has ben created",
                message : "must wait 30 minute for activation and !",
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                  duration: 4000,
                }
              });

        }catch(e){
          store.addNotification({
            title: "Error",
            message : "Your balance is insufficient !",
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            }
          });

        }

       setInputs ({
       ...inputs
         });
        
          }

    return (
    <Modal isOpen= {props.isOpen} toggle={props.toggle} modalTransition= {{timeout: 100}} className="modal-default">
                      <Form role="form"  onSubmit={handleSubmit}>

    <ModalHeader toggle={props.toggle}>{props.title}</ModalHeader>
    <ModalBody>
        <Table className="align-items-center table-flush table-dark text-light" responsive>
         
            <tbody>
            <tr>
                <td>Type :</td>
                <td>    
              <Input className="bg-default " type="select" name="abonnemen_type" id="exampleSelect"  onChange = {(e)=>onClickType(e)}>
              {props.priceLists.map(priceList => <option key={priceList.id} value= {priceList.id}>{priceList.type}</option>)}              
              </Input>
</td>
            </tr>
            <tr>
                <td> Period :</td>
                <td>
                <Input className="bg-default "  type="select" name="period" id="exampleSelect" defaultValue={defaultValue} onChange = {(e)=>HandleClick(e)}>
              {props.periods.map(periods => <option key={periods.id} value= {periods._id}>{periods.period} </option>)}              
              </Input>
                </td>
            </tr>
            <tr>
            <td>
                <label>Price :</label>
              </td>
              <td >
                <h3 className='text-white bg-success form-control text-center'>{price} Credit</h3>
              </td>
            </tr>
            <tr>
                <td>Note:</td>
                <td><Input className="bg-default" name='note' type='textarea' 
                                              onChange= {handleChange}

                /></td>
            </tr>
            <tr>
                <td>Client name :</td>
                <td><Input className="bg-default" name='clientName' 
                                              onChange= {handleChange}

                /></td>
            </tr>
            <tr>
                <td>Phone:</td>
                <td><Input className="bg-default" name='phone' 
                                              onChange= {handleChange}

                /></td>
            </tr>

            <tr>
                <td>Email :</td>
                <td><Input className="bg-default" name='email' 
                                              onChange= {handleChange}

                /></td>
            </tr>
             
            </tbody>
        </Table>
    
    </ModalBody>
    <ModalFooter>
      <Button color="primary" type = 'submit'>Save</Button>{' '}
      <Button color="secondary" onClick={props.toggle}>Cancel</Button>
    </ModalFooter>
    </Form>
  </Modal>
    )
 
  }

export default CreateModal ;
