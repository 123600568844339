
import React from "react";
// reactstrap components
import {

  Modal,
  ModalBody,
  ModalHeader,
  Table,
} from "reactstrap";

const ShowModal = (props) => {
    return (
    <Modal isOpen= {props.isOpen} toggle={props.toggle} modalTransition= {{timeout: 100}}>
                        <div className = "card border-0 bg-default">

    <ModalHeader toggle={props.toggle}><div className = "text-white">{props.title}</div></ModalHeader>
    <ModalBody>
    <Table className="align-items-center table-dark table-flush" responsive>
         
            <tbody>
            <tr>
                <td>name :</td>
                <td>{props.body.name}</td>
            </tr>
            <tr>
                <td>Email :</td>
                <td>{props.body.email}</td>
            </tr>
            <tr>
                <td>Phone </td>
                <td>{props.body.phone}</td>
            </tr>
            <tr>
                <td>Adress:</td>
                <td>{props.body.adress}</td>
            </tr>
            <tr>
                <td>Credit :</td>
                <td>{props.body.credit}</td>
            </tr>
            <tr>
                <td>Role </td>
                <td>{props.body.role}</td>
            </tr>
            </tbody>
        </Table>
    
    </ModalBody>
</div>
  </Modal>
    )
 
  }


export default ShowModal;
