
import React, { useState,useEffect } from "react";
// reactstrap components
import {

  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Table,
  Input,
  Form
} from "reactstrap";


// core components
import {ApiClients} from "../../services" ;
import { store } from 'react-notifications-component';
import {mutate, trigger} from "swr";


const EditModal = (props) => {


  useEffect(() => {
    setInputs ({
      email : props.body.email ,
      phone : props.body.phone ,
      clientName : props.body.clientName ,
      note : props.body.note
    })
 },[props.body]);

    const [inputs , setInputs] = useState ({
        email : props.body.email ,
        phone : props.body.phone ,
        clientName : props.body.clientName ,
        note : props.body.note 

       
       },[]);

       
         function handleChange(e) {
           const { name, value } = e.target;
           setInputs(inputs => ({ ...inputs, [name]: value }));
           }

        
           async function handleSubmit(e) {
             e.preventDefault();
            props.toggle();
            try {
              const url =  'http://localhost:3000/api/v1/clients/myAccounts' ; 
              mutate(url) ;
           await ApiClients.patch(props.body._id,inputs);
           trigger(url);

           store.addNotification({
            title: "Success",
            message : "Account information has ben changed!",
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            }
          });
        }catch(e){
          store.addNotification({
            title: "Error",
            message : "Validation error !!!",
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            }
          });
        }
          }


    return (
      <>
    <Modal  isOpen= {props.isOpen} toggle={props.toggle} modalTransition= {{timeout: 100}}>
                      <Form role="form" onSubmit={handleSubmit}  >

    <ModalHeader toggle={props.toggle}>{props.title}</ModalHeader>
    <ModalBody>
        <Table className="align-items-center table-flush" responsive>
         
            <tbody>
         
            <tr>
                <td>Client name :</td>
                <td><Input name='clientName' defaultValue={inputs.clientName} 
                                              onChange= {handleChange}

                /></td>
            </tr>
            <tr>
                <td>Phone:</td>
                <td><Input name='phone' defaultValue={inputs.phone}
                                              onChange= {handleChange}

                /></td>
            </tr>
            <tr>
                <td>Email :</td>
                <td><Input name='email' defaultValue={inputs.email}
                                              onChange= {handleChange}

                /></td>
            </tr>
            <tr>
                <td>Note :</td>
                <td><Input type="textarea" name='note' defaultValue={inputs.note}
                                              onChange= {handleChange}

                /></td>
            </tr>
            
            </tbody>
        </Table>
    
    </ModalBody>
    <ModalFooter>
      <Button color="primary"  type="submit">Update</Button>{' '}
      <Button color="secondary" onClick={props.toggle}>Cancel</Button>
    </ModalFooter>
    </Form>

  </Modal>

  </>
    )
 
  }


  export default EditModal ;
