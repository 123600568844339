
import React from "react";
import { useEffect,useState } from "react";

// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";
import { connect } from "react-redux";

const DashboardHeader = (props) =>  {
  

    return (
      <>
        <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
          <Container fluid>
            <div className="header-body">
              {/* Card stats */}
              <Row>
                <Col lg="6" xl="4">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                          Actif Accounts
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {props.data.actifAccounts}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-success text-white rounded-circle shadow">
                            <i className="fas fa-check" />
                          </div>
                        </Col>
                      </Row>
       
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6" xl="4">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Disabled Accounts

                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {props.data.disabledAccounts }
                         </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                            <i className="fas fa-times" />
                          </div>
                        </Col>
                      </Row>
       
                    </CardBody>
                  </Card>
                </Col>
        
                <Col lg="6" xl="4">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            wallet
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                          {props.userInfo.credit}     credits      
                         </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-default text-white rounded-circle shadow">
                            <i className="ni ni-credit-card" />
                          </div>
                        </Col>
                      </Row>
               
                    </CardBody>
                  </Card>
                </Col>
     
              </Row>
            </div>
          </Container>
        </div>
      </>
    );
  }

  const mapStateToProps = (state) => {
    return {
      userInfo : state.auth.userInfo ,
    }
  }

export default connect(mapStateToProps) (DashboardHeader);
