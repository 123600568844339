
import React from "react";
import 
{
    Col , Card
} from "reactstrap";

const NotFound = () => {

  return (
      
      <>      <Col lg="8" md="7">
        <Card className="bg-secondary shadow border-0">
<br/><br/>
     <div class="container">
    <div class="row">
        <div class="xs-12 md-6 mx-auto">
            <div id="countUp">
            <div class="display-1 ">404</div>
                <div class="display-3">Page not found</div>
                <div class="text">This may not mean anything.</div>
                <div class="text">I'm probably working on something that has blown up.</div>
            </div>
        </div>
    </div>
</div>     <br/><br/>       
            </Card>
            </Col>
      </>
    );
  }

export default NotFound ; 