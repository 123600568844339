import React, { useState } from 'react';
import { CustomInput, FormGroup,
    Card,
    CardHeader,
    CardBody,
    Row,
    Col,
    Button
} from 'reactstrap';
import { connect } from "react-redux";
import  { updateProfile } from "../../actions/user";
import { withRouter } from "react-router-dom";
import { store } from 'react-notifications-component';

const  UploadFile = (props) => {  
    const [state , setState] =  useState (
        {
            fileName: '',
            invalidFile: false,
            file : "img/users/"+props.userInfo.photo
          }
    ) ;

    const [file, setFile] = useState(''); // storing the uploaded file    // storing the recived file from backend

  const handleFileChange = async(e) => {
    const cancel = !e.target.files.length;
    const file = e.target.files[0]; // accesing file
    if (cancel) return;

    const size = file.size ;
    const name = file.name
    const maxSize = 5000000;

    if (size < maxSize) {
     setState({ fileName: name, invalidFile: false ,file: URL.createObjectURL(file) });
      setFile(file);

    } else {
      setState({ fileName: '', invalidFile: true });
    }
  }

  async function handleSubmit ()
  {
    const formData = new FormData();
    formData.append('photo', file); 
    try {
      if (file)
      {
    await props.updateProfile(formData); 
    store.addNotification({
      title: "Success",
      message : "Image updated!",
      type: "success",
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 2000,
      }
    });    
    }
   } catch (e)
     {
      store.addNotification({
        title: "Error",
        message : "update error check image size !!!",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 2000,
        }
      });     }
  }


    const { fileName, invalidFile } = state;
    return (
        <>
        <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
        <Card className="card-profile shadow">
          <Row className="justify-content-center">
            <Col className="order-lg-2" lg="3">
              <div className="card-profile-image">
                  <img
                    alt="..."
                    className="rounded-circle"
                    src={state.file}
                  />
              </div>
            </Col>
          </Row>
          <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
          
          </CardHeader>
          <CardBody className="pt-0 pt-md-4">
            <Row>
              <div className="col">
                <div className="card-profile-stats d-flex justify-content-center mt-md-5">
                 
                </div>
              </div>
            </Row>
            <div className="text-center">
            <FormGroup>
        <CustomInput
          type="file"
          id="exampleCustomFileBrowser"
          name="photo"
          label={fileName || 'choose an image'}
          onChange={handleFileChange}
          invalid={invalidFile} />
      </FormGroup>                   
       <Button color="primary" size="xs" type="button" onClick={handleSubmit}>Update photo             </Button>
  </div>
          </CardBody>

        </Card>
      </Col>  
</>
    );
  }
  const mapStateToProps = (state) => {
    return {
      userInfo : state.auth.userInfo ,
      error : state.error , 
      isOpenAlert : state.alert.isOpenAlert, 
      text : state.alert.text , 
      color : state.alert.color, 
    }
  }
  export default withRouter (connect(mapStateToProps,{updateProfile})(UploadFile) ) ; 