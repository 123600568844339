
import React, { useState } from "react";
import {

    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,

  } from "reactstrap";
  import EditStatusModal from '../ModalTransactions/EditStatusToken.jsx';


const ActionsTransactions =(props) => {

    const [editModal, setEditModal] = useState(false);
    const toggleEdit = () => setEditModal(!editModal);


    return(<>
 <UncontrolledDropdown>
   <DropdownToggle
     className="btn-icon-only text-light"
     href={e => e.preventDefault()}
     role="button"
     size="sm"
     color=""
     onClick={e => e.preventDefault()}
   >
     <i className="fas fa-ellipsis-v" />
   </DropdownToggle>
   <DropdownMenu className="dropdown-menu-arrow" right>

     <DropdownItem
            className="btn"
       href={toggleEdit}
       onClick={toggleEdit}
     >
       Edit payement status
     </DropdownItem>

   </DropdownMenu>
 </UncontrolledDropdown>


        <EditStatusModal Transactions = {props.Transactions} title = 'Edit Status token' body = {props.id.row.original} isOpen= {editModal} toggle={toggleEdit} >
        </EditStatusModal>
  

     


</>


    )
}
export default ActionsTransactions ;