
import React, { useState } from "react";
// reactstrap components
import {

  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Table,
  Input,
  Form
} from "reactstrap";

// core components
import {ApiUsers} from "../../services" ;
import { store } from 'react-notifications-component';



const EditCreditModal = (props) => {


 
    const [inputs , setInputs] = useState ({
      credit : props.body.credit , 
      newCredit : props.body.credit ,
      user : props.body._id ,

       
       },[])
         function handleChange(e) {

           const { name, value } = e.target;
           setInputs(inputs => ({ ...inputs, [name]: value }));
           }
           
           async function handleSubmit() {
            props.toggle();
            try {
           await ApiUsers.patch('updateCredit',inputs);
           store.addNotification({
            title: "Success",
            message : "Account credit has ben updated!",
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            }
          });
          setInputs({...inputs,credit : inputs.newCredit});
           props.Users();  
        }catch(e){
          store.addNotification({
            title: "Error",
            message : "Validation error!",
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            }
          });        
        }
          }

    return (
      <>
    <Modal isOpen= {props.isOpen} toggle={props.toggle} modalTransition= {{timeout: 100}}>
                      <Form role="form"  >

    <ModalHeader toggle={props.toggle}>{props.title}</ModalHeader>
    <ModalBody>
        <Table className="align-items-center table-flush" responsive>
         
            <tbody>
            <tr>
                <td>Credit:</td>
                <td><Input type='number' name='newCredit' defaultValue={inputs.newCredit}
                                              onChange= {handleChange}

                /></td>
            </tr>
     

         
            </tbody>
        </Table>
    
    </ModalBody>
    <ModalFooter>
      <Button color="primary" onClick={handleSubmit}>Update</Button>{' '}
      <Button color="secondary" onClick={props.toggle}>Cancel</Button>
    </ModalFooter>
    </Form>

  </Modal>

  </>
    )
 
  }


  export default EditCreditModal ;
