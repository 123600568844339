
import React, { useState,useEffect } from "react";
// reactstrap components
import {

  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Table,
  Input,
  Form
} from "reactstrap";

// core components
import {ApiClients,ApiPriceList} from "../../services" ;
import moment from 'moment';
import { store } from 'react-notifications-component';
import {mutate, trigger} from "swr";

const EditModal = (props) => {
 
  useEffect(() => {
      setInputs ({
        abonnement_type : props.body.period.type,
        email : props.body.email ,
        end_date : props.body.end_date,
        starter_date : props.body.starter_date,
        username : props.body.username , 
        password : props.body.password,
        period : props.body.period,
        note : props.body.note,
        phone : props.body.phone ,
        status : props.body.status,
        clientName : props.body.clientName  
      })
   },[props.body]);



    const [inputs , setInputs] = useState ({
        abonnement_type : props.body.period.type,
        email : props.body.email ,
        end_date : props.body.end_date,
        starter_date : props.body.starter_date,
        username : props.body.username , 
        password : props.body.password,
        period : props.body.period,
        phone : props.body.phone ,
        note : props.body.note,
        status : props.body.status,
        clientName : props.body.clientName    
       }); 

         function handleChange(e) {
           const { name, value } = e.target;
           setInputs(inputs => ({ ...inputs, [name]: value }));
           console.log(inputs)
           }
           
           function HandleClick (e)  {
            const { name, value } = e.target;
            setInputs(inputs => ({ ...inputs, [name]: value }));
          }

        
           async function handleSubmit(e) {
             e.preventDefault();
            try {
              const url = 'http://itautronic.com/api/v1/clients'
              mutate(url) ;
           await ApiClients.patch(props.body._id,inputs);
           trigger(url);

           store.addNotification({
            title: "Success",
            message : "Account has ben updated!",
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            }
          });
           props.toggle();  
        }catch(e){
          store.addNotification({
            title: "Error",
            message : "Validation error !!!",
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            }
          });
        }
          }


        const starterDate = moment(props.body.starter_date).format("YYYY-MM-DD");
        const endDate = moment(props.body.end_date).format("YYYY-MM-DD");


        function onClickType (e)  {
          ApiPriceList.get('/'+e.target.value).then(res => { 
          const periods = res.data.data.data.periods ;
          props.setPeriods(periods);
          const period = periods[0];
          setInputs({...inputs,period});
          })

        }

    return (
      <>
    <Modal isOpen= {props.isOpen} toggle={props.toggle} modalTransition= {{timeout: 100}} className="modal-default">
                      <Form role="form" onSubmit={handleSubmit}  >

    <ModalHeader toggle={props.toggle}>{props.title}</ModalHeader>
    <ModalBody>
        <Table className="align-items-center table-flush text-light table-dark" responsive>
         
            <tbody>
            <tr>
                <td>Type :</td>
                <td>    <Input className="bg-default" defaultValue= {inputs.abonnement_type.id} type="select" name="select" id="typeSelect"  onChange = {(e)=>onClickType(e)}>
              {props.priceLists.map(priceList => <option key={priceList.id} value= {priceList.id}>{priceList.type}</option>)}              
              </Input></td>
            </tr>
            <tr>
                <td> Period :</td>
                <td>    <Input className="bg-default"  defaultValue={inputs.period._id} type="select" name="period" id="periodSelect"  onChange = {(e)=>HandleClick(e)}>
              {props.periods.map(period => <option key={period.id} value= {period._id}>{period.period}</option>)}              
              </Input></td>
            </tr>

            <tr>
                <td>Client name :</td>
                <td><Input  className="bg-default" name='clientName' defaultValue={inputs.clientName} 
                                              onChange= {handleChange}

                /></td>
            </tr>
            <tr>
                <td>Phone:</td>
                <td><Input className="bg-default" name='phone' defaultValue={inputs.phone}
                                              onChange= {handleChange}

                /></td>
            </tr>
            <tr>
                <td>Email :</td>
                <td><Input className="bg-default" name='email' defaultValue={inputs.email}
                                              onChange= {handleChange}

                /></td>
            </tr>
            <tr>
                <td>Note :</td>
                <td><Input  className="bg-default" type='textarea' name='note' defaultValue={inputs.note}
                                              onChange= {handleChange}

                /></td>
            </tr>
            <tr>
                <td> Status :</td>
                <td><Input className="bg-default" name='status' type='select' defaultValue={inputs.status}
                                              onChange= {HandleClick}

                >
                    <option>enabled</option>
                    <option>in progress</option>
                    <option>disabled</option>
                    </Input></td>
            </tr>
         
            <tr>
                <td>Creation Date :</td>
                <td><Input className="bg-default" name='starter_date' type='date' defaultValue= {starterDate} 
                                              onChange= {HandleClick}
                                              /></td>
            </tr>
            <tr>
                <td>Expiration Date :</td>
                <td><Input className="bg-default" name='end_date' type='date' defaultValue= {endDate} 
                                              onChange= {HandleClick}
                                              /></td>
            </tr>
            <tr>
                <td>Username :</td>
                <td><Input className="bg-default" name= 'username' defaultValue={inputs.username}          
                                     onChange= {handleChange}
/></td>
            </tr>
            <tr>
                <td>Password :</td>
                <td><Input className="bg-default" name='password' defaultValue={inputs.password} 
                                              onChange= {handleChange}
                /></td>
            </tr>
         
            </tbody>
        </Table>
    
    </ModalBody>
    <ModalFooter>
      <Button color="primary" type="submit">Update</Button>{' '}
      <Button color="secondary" onClick={props.toggle}>Cancel</Button>
    </ModalFooter>
    </Form>

  </Modal>

  </>
    )
 
  }


  
  export default EditModal ; 
  

