
import React , {useState} from "react";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Button,
  ListGroup,
  li
} from "reactstrap";
// core components
import Header from "components/Headers/Header.jsx";
import ProgramsModal from "components/ModalPriceLists/programs.jsx"
// import {ApiPriceList , ApiPeriod } from "services" ;

const PriceList = () => {

    const [comp, setComp] = useState(false);
    const [classCss, setClassCss] = useState('');

    const [programsModal, setProgramsModal] = useState(false);
    
    const togglePrograms = () => setProgramsModal(!programsModal);

    const liteClick = () => {
        togglePrograms();
        setComp(Lite);
        setClassCss("modal-content bg-gradient-success text-white");

    }
    const partsClick = () => {
        togglePrograms();
        setComp(Parts);
        setClassCss("modal-content bg-gradient-info text-white");


    }
    const workshopClick = () => {
        togglePrograms();
        setComp(Workshop);
        setClassCss("modal-content bg-gradient-default text-white");

    }
    const fullClick = () => {
        togglePrograms();
        setComp(Full);
        setClassCss("modal-content bg-gradient-danger text-white");

    }

    const Lite =  () => 
    {
        return (
            <>
             <Row>
        <Col>
        <ul>
        Parts :
       <hr/>
       <li> Nissan </li> 
       <li> toyota</li>
       <li>Mazda offline</li>
       <li> mercedes EPC </li> 
       <li>Isuzu + Suzuki epc </li>
       <li> kia</li>
       <li>mitsubishi</li> 
       <li>teccat (online for now)</li>
       <li> Hyundai</li>
       <li>Suzuki (online for now)</li> 
       <li>PSA (online for now)</li> 
       <li> Iveco</li>
       <li>bmw</li>
       <li>ford</li>
       <li> GM (online and offline)</li>

        </ul>
        </Col>
        <Col>
        Workshop : 
         <hr></hr>
        <li>mercedes wis</li>
        <li>bosch esi-tronic</li>
        <li> elsawin 6 and 4</li>
        <li> servicebox peugeot and citroen</li>
        <li>dialogys for renault</li>

        </Col>
        </Row>
            </>
        )
    }

    const Parts =  () => 
    {
        return (
            <>
             <Row>
             <Col>

            <ul>
       <li> Nissan                  </li> 
       <li> toyota                  </li>
       <li>Mazda offline            </li>
       <li> mercedes EPC + online   </li> 
       <li>Isuzu + Suzuki epc       </li>
       <li> kia offline + online    </li>
       <li>mitsubishi               </li> 
       <li>teccat online            </li>
       <li> Hyundai offline + online</li>
       <li>Suzuki online            </li>
       </ul> 
        </Col>

<Col>

                   
 <ul>
            <li >PSA online </li> 
            <li > Iveco</li>
            <li >bmw</li>
            <li >ford</li>
            <li > GM (online and offline)</li>
            <li >partslink24</li>
            <li >ETKA</li>
            <li >Renault</li>
            </ul>  
</Col>
        </Row>
            </>
        )
    }

    const Workshop =  () => 
    {
        return (
            <>
             <Row>
        <Col>
        <ul>
        <li>mercedes wis</li>
        <li>bosch esi-tronic</li>
        <li> elsawin 6 and 4</li>
        <li> servicebox peugeot and citroen</li>
        <li>dialogys for renault</li>
        </ul>
        </Col>
        <Col>
        <ul>
        <li>peugeot online</li>
        <li>autodata FR</li>
        <li> Haynes Pro FR </li>
        <li> ALLDATA EN</li>
        </ul>
        </Col>
        </Row>
            </>
        )
    }

    const Full =  () => 
    {
        return (
            <>
             <Row>
        <Col>
        <ul>
        Parts :
       <hr/>
       <li> Nissan                  </li> 
       <li> toyota                  </li>
       <li>Mazda offline            </li>
       <li> mercedes EPC + online   </li> 
       <li>Isuzu + Suzuki epc       </li>
       <li> kia offline + online    </li>
       <li>mitsubishi               </li> 
       <li>teccat online            </li>
       <li> Hyundai offline + online</li>
       <li>Suzuki online            </li>
       <li >PSA online </li> 
            <li > Iveco</li>
            <li >bmw</li>
            <li >ford</li>
            <li > GM (online and offline)</li>
            <li >partslink24</li>
            <li >ETKA</li>
            <li >Renault</li>

        </ul>
        </Col>
        <Col>
        Workshop : 
         <hr></hr>
        <li>mercedes wis</li>
        <li>bosch esi-tronic</li>
        <li> elsawin 6 and 4</li>
        <li> servicebox peugeot and citroen</li>
        <li>dialogys for renault</li>
        <li>peugeot online</li>
        <li>autodata FR</li>
        <li> Haynes Pro FR </li>
        <li> ALLDATA EN</li>
        </Col>
        </Row>
            </>
        )
    }
  // function fetchUsers () { 
  //   ApiPriceList.get().then(res => {
  //     const tbody = res.data.data.data.reverse() ; 
  //     console.log(tbody);
  //     setState({tbody});

  //   });
  // }

  // useEffect(() => {
  //     fetchUsers();  
  // },[]);

  // const [state,setState] = useState ({
  //   tbody : [],
  // })

    return (
      <>
        <Header />
        {/* Page content */}

        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
            <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3  className="mb-0">Price list</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                   1 credit = 3€ / 3,6$ / 280 RUB / 10 TND
                    </Col>
                  </Row>
                </CardHeader>
<CardBody >
<div class="display-3 ">Our credit system : </div> 
<div class="display-4 "> 1 credit = 3 €</div>
<div class="display-4 "> 1 credit = 3,6 $</div>
<div class="display-4 "> 1 credit = 280 RUB</div>
<div class="display-4 "> 1 credit = 10 TND</div>


<br/>
  <Row>
    <Col>

<div class="card card-pricing bg-gradient-success border-0 text-center mb-4">
    <div class="card-header bg-transparent">
        <h4 class="text-uppercase ls-1 text-white py-3 mb-0">LITE PACK</h4>
    </div>
    <div class="card-body px-lg-7">
        <div class="display-2 text-white">15 credit</div>
        <span class=" text-white">per account/year</span>

        <ul class="list-unstyled my-4">
        <li>
                <div class="d-flex align-items-center">
                <div>

                <span class="text-s text-white">LITE is means everything is offline (parts and workshop) </span> 
                        </div>
                </div>
            </li><br/>            <hr></hr>

            <li>
                <div class="d-flex align-items-center">
                <div>

                        <span class="pl-2 text-s text-white">1 month : 2 credit </span>
                        </div>
                </div>
            </li><br/>
            <li>
                <div class="d-flex align-items-center">
                 
                    <div>
                        <span class="pl-2 text-s text-white">3 months : 5 credit</span>
                    </div>
                </div>
            </li><br/>
            <li>
                <div class="d-flex align-items-center">
               
                    <div>
                        <span class="pl-2 text-s text-white">6 months : 9 credit</span>
                    </div>
                </div>
            </li>
        </ul> 
    </div>
    <div class="card-footer bg-transparent">
        <Button onClick={liteClick} >Click here to see available programs </Button>
      </div>
</div>
</Col>
<Col>
<div class="card card-pricing bg-gradient-info border-0 text-center mb-4">
    <div class="card-header bg-transparent">
        <h4 class="text-uppercase ls-1 text-white py-3 mb-0">Parts pack</h4>
    </div>
    <div class="card-body px-lg-7">
        <div class="display-2 text-white">44 credit</div>
        <span class=" text-white">per account/1 year</span>
        <ul class="list-unstyled my-4">
        <li>
                <div class="d-flex align-items-center">
                <div>

                <span class="text-s text-white">Parts FULL is means everything is offline and online (parts)  </span> 
                        </div>
                </div>
            </li><br/>
            <hr></hr>

            <li>
                <div class="d-flex align-items-center">
                <div>

                        <span class="text-s text-white">1 month : 5 credit </span>
                        </div>
                </div>
            </li><br/>
            <li>
                <div class="d-flex align-items-center">
                 
                    <div>
                        <span class=" text-s text-white">3 months : 13 credit</span>
                    </div>
                </div>
            </li><br/>
            <li>
                <div class="d-flex align-items-center">
               
                    <div>
                        <span class=" text-s text-white">6 months : 24 credit</span>
                    </div>
                </div>
            </li>
        </ul>
    </div>
    <div class="card-footer bg-transparent">
    <Button onClick={partsClick}>Click here to see available programs </Button>
    </div>
</div>
</Col>
<Col>
<div class="card card-pricing bg-gradient-default border-0 text-center mb-4">
    <div class="card-header bg-transparent">
        <h4 class="text-uppercase ls-1 text-white py-3 mb-0">Workshop pack</h4>
    </div>
    <div class="card-body px-lg-7">
        <div class="display-2 text-white">44 credit</div>
        <span class=" text-white">per account/year</span>
        <ul class="list-unstyled my-4">
        <li>
                <div class="d-flex align-items-center">
                <div>

                <span class="text-s text-white">Workshop FULL is meaning all workshop softwares include offline and online available in two language (English & French) </span> 
                        </div>
                </div>
            </li><br/>
            <hr></hr>
            <li>
                <div class="d-flex align-items-center">
                <div>

                        <span class="pl-2 text-s text-white">1 month : 5 credit </span>
                        </div>
                </div>
            </li><br/>
            <li>
                <div class="d-flex align-items-center">
                 
                    <div>
                        <span class="pl-2 text-s text-white">3 months : 13 credit</span>
                    </div>
                </div>
            </li><br/>
            <li>
                <div class="d-flex align-items-center">
               
                    <div>
                        <span class="pl-2 text-s text-white">6 months : 24 credit</span>
                    </div>
                </div>
            </li>
        </ul>
    </div>
    <div class="card-footer bg-transparent">
    <Button onClick={workshopClick}>Click here to see available programs </Button>
    </div>
</div>
</Col>

</Row>
<Col>
<div class="card card-pricing bg-gradient-danger border-0 text-center mb-4">
    <div class="card-header bg-transparent">
        <h4 class="text-uppercase ls-1 text-white py-3 mb-0">Full pack</h4>
    </div>
    <div class="card-body px-lg-7">
        <div class="display-2 text-white">50 credit</div>
        <span class=" text-white">per account/year</span>
        <ul class="list-unstyled my-4">
        <li>
                <div class="d-flex align-items-center">
                <div>

                <span class="text-s text-white">FULL is means everything offline and online (parts and workshop)  </span> 
                        </div>
                </div>
            </li><br/>
            <hr></hr>
            <li>
                <div class="d-flex align-items-center">
                <div>

                        <span class="text-s text-white">1 month : 6 credit </span>
                        </div>
                </div>
            </li><br/>
            <li>
                <div class="d-flex align-items-center">
                 
                    <div>
                        <span class="text-s text-white">3 months : 15 credit</span>
                    </div>
                </div>
            </li><br/>
            <li>
                <div class="d-flex align-items-center">
               
                    <div>
                        <span class="text-s text-white">6 months : 28 credit</span>
                    </div>
                </div>
            </li>
        </ul>
    </div>
    <div class="card-footer bg-transparent">
    <Button onClick={fullClick}>Click here to see available programs </Button>
    </div>
</div>
</Col>
</CardBody>





              </Card>
            </div>
          </Row>
        </Container>
        <ProgramsModal class = {classCss} title="program lists" isOpen= {programsModal} toggle={togglePrograms} comp={comp}></ProgramsModal>

      </>
    );
  }


export default PriceList ;















