
import React from "react";
// reactstrap components
import {

  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";

// core components

const ShowModal = (props) => {

    return (
    <Modal isOpen= {props.isOpen} toggle={props.toggle} modalTransition= {{timeout: 100}} >
            <div className={props.class}>

    <ModalHeader toggle={props.toggle}><div className="text-white">{props.title}</div></ModalHeader>
    <ModalBody>
     {props.comp}
    </ModalBody>
    </div>
  </Modal>
    )
 
  }


export default ShowModal;
