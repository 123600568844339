import React from "react";
import ReactDOM from "react-dom";
import { HashRouter, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import Store from "./store";

import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";

/* react-notification import  */
import 'animate.css';
import 'react-notifications-component/dist/theme.css'
import ReactNotification from 'react-notifications-component'; 

import AdminLayout from "layouts/Admin.jsx";

import AuthLayout from "layouts/Auth.jsx";
import { CookiesProvider } from 'react-cookie';
import { SWRConfig } from "swr";
import { ApiClients } from "./services";


ReactDOM.render(
  
  <CookiesProvider>
  <Provider store={Store}>
    <HashRouter>
    <ReactNotification/>
    <SWRConfig value={{refreshInterval : 5000 }}>
      <Switch>
        <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
        <Route path="/" render={(props) => <AuthLayout {...props} />} />
        <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
        {/* add invalid url page 404 not found */}
      </Switch>
      </SWRConfig>
    </HashRouter>
  </Provider>
  </CookiesProvider>,
  document.getElementById("root")
);
