
import React from "react";
// reactstrap components
import {

  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Form,
  
} from "reactstrap";

// core components
import {ApiUsers} from "../../services" ;
import { connect } from "react-redux";
import {setAlert} from '../../actions/alert.js';
import { withRouter } from "react-router-dom";
import hideAlert from '../alerts/hideAlert'
const DeleteModal = (props) => {

           async function handleSubmit() {
            props.toggle();
            try {
           await ApiUsers.delete(props.body.id);
           const a = { 
            isOpenAlert : true ,
            color : 'success',
            text : 'Account deleted'
           }
           props.setAlert(a);
           hideAlert(a,props);
           props.Users();
        }catch(e){
          const a = { 
            isOpenAlert : true ,
            color : 'danger',
            text : 'Error'
           }
           props.setAlert(a);
           hideAlert(a,props);
        }
          }

          
         
    return (<>
    <Modal isOpen= {props.isOpen} toggle={props.toggle} modalTransition= {{timeout: 100}}>
                      <Form role="form"  >

    <ModalHeader toggle={props.toggle}>{props.title}</ModalHeader>
    <ModalBody>
    you sure you want to delete this account !!    
    </ModalBody>
    <ModalFooter>
      <Button color="primary" onClick={handleSubmit}>Delete</Button>{' '}
      <Button color="secondary" onClick={props.toggle}>Cancel</Button>
    </ModalFooter>
    </Form>

  </Modal>

 </>
    )
 
  }

  const mapStateToProps = (state) => {
    return {
      isOpenAlert : state.alert.isOpenAlert, 
      text : state.alert.text , 
      color : state.alert.color, 
    }
  }
  export default withRouter( connect(mapStateToProps,{setAlert})(DeleteModal)) ;
