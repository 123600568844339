
import React, { useState, useEffect } from "react";
// reactstrap components
import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  Button,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.jsx";
import {ApiUsers} from "../../../services" ;
import moment from 'moment';
import Actions from "../../../components/tables/ActionsUsers";
import { useHistory } from 'react-router-dom';
import Tables from "views/pages/TableWhite.jsx";


const Users = () => {

  function fetchUsers () { 
    ApiUsers.get().then(res => {
      const tbody = res.data.data.data.reverse() ; 
      setState({tbody});

    });
  }

  useEffect(() => {
      fetchUsers();  
  },[]);

  const [state,setState] = useState ({
    tbody : [],
  })



    const history = useHistory();


    const handleClick = () => {
      history.push({pathname : "/admin/CreateUser"});
  }


  function photo (p)
  {
    return (
      <span className="avatar avatar-sm rounded-circle">
      <img alt='...' src = {"img/users/"+p}/>
      </span>
            )
  }

const columns = React.useMemo (
  () => [
 
    {
      Header: ' ' , 
      accessor : properties => photo(properties.photo),
    },
    {
      Header: 'Email' , 
      accessor : properties => properties.email,
    },
    {
      Header: 'Name' , 
      accessor : 'name',
    },
    {
      Header: 'Role' , 
      accessor : 'role',
    },
    {
      Header: 'Creation date' , 
      accessor : properties => convertDate(properties.CreatedAt),
    },
    {
      Header: 'Credit' , 
      accessor : 'credit',
    },
    {
      id: 'selection',
      Header: () => (
        <div>
          Actions
        </div>
      ), 
      Cell: props => (
        <div>
          <Actions Users = {fetchUsers} id = {props}   />
        </div>
      ),
        },
  ],[]
)



  function convertDate ( date )
  {
    return moment(date).format("DD/MM/YYYY");
  }



    return (
      <> 
        <Header />
        {/* Page content */}

        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
            <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3  className="mb-0">Users list</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                      <Button
                        color="primary"
                        onClick={handleClick}
                        size="sm"
                      >
                        Create new user
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>


                <Tables columns = {columns} tbody = {state.tbody} />

              </Card>
            </div>
          </Row>
        </Container>

      </>
    );
  }



export default Users ;















