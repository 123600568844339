import {ApiUsers} from "../services"; // to make ApiUsers call
//import types
import * as TYPE from "./types";



export const getProfile = () => async (dispatch) => {
    try {
      const response = await ApiUsers.get('/me');
      dispatch({
        type: TYPE.CLEAR_ERRORS,
      });
      dispatch({
        type: TYPE.GET_PROFILE,
        payload: {
          isLogged: true,
          email: response.data.data.email,
          role: response.data.data.role,
        },
      });
    } catch (err) {
      //case logout fail
      return dispatch({
        type: TYPE.RETURN_ERRORS,
        payload: {
          name: "stay logged error ",
          message: "token problem",
        },
      });
    }
  };
  

  export const updateProfile  = (obj) => async (dispatch) => {
    try {
      const response = await ApiUsers.patch('/updateMe',obj);
      dispatch({
        type: TYPE.CLEAR_ERRORS,
      });
      dispatch({
        type: TYPE.UPDATE_USER,
        payload: {
          isLogged: true,
          email: response.data.data.email,
          role: response.data.data.role,
        },
      });
      return response ;
    } catch (err) {
      //case logout fail
      return dispatch({
        type: TYPE.RETURN_ERRORS,
        payload: {
          name: "stay logged error ",
          message: "token problem",
        },
      });
    }
  };