import * as TYPE from "../actions/types";

const initialState = {
    isOpenAlert : false , 
    text : '' ,
    color : '' , 
};

const alertReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPE.SET_ALERT:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default alertReducer;
